<template>
    <v-container fluid class="px-0" style="height: 100%;">
        <v-card-text class="px-0">
            <v-row style="height: 100%;">
                <v-col cols="12" md="12" class="px-0">
                    <v-tabs v-model="tab" align-tabs="start" hide-slider class="px-3">
                        <v-tab :class="tab == 1 ? 'bg-secondary text-white' : 'bg-grey-app-2 text-black'"
                            class="rounded-t-lg text-capitalize" :value="1">Crear Registro</v-tab>
                        <v-tab :class="tab == 2 ? 'bg-secondary text-white' : 'bg-grey-app-2 text-black'"
                            class="rounded-t-lg text-capitalize" :value="2">Ver Registro</v-tab>
                    </v-tabs>
                    <v-divider inset class="mr-3"></v-divider>

                    <v-tabs-window v-model="tab">
                        <v-tabs-window-item :value="1">
                            <v-card-text class="px-0">

                                <v-row>
                                    <v-col md="12">
                                        <div class="d-flex">
                                            <v-spacer></v-spacer>
                                            <v-spacer></v-spacer>
                                            <v-text-field v-model="searchTerm" variant="outlined" bg-color="white"
                                                placeholder="Buscar encuesta" density="compact">
                                                <template v-slot:append-inner>
                                                    <v-icon class="opacity-100" color="primary">mdi-magnify</v-icon>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" md="4" v-for="(item, index) in filteredSurveys" :key="index">
                                        <v-hover>
                                            <template v-slot:default="{ isHovering, props }">
                                                <div v-bind="props" class="survey-card rounded-lg"
                                                    :class="combineClasses(isHovering, item, index)"
                                                    :style="getAnimationStyle(index + 1, 200)">
                                                    <div class=" survey-image overflow-hidden" style="background-position: center;
    background-size: cover;" :style="{ 'background-image' : 'url('+item.imageUrl+')' }">
                                                        <!-- <v-img
                                                            :class="{ 'animate__animated animate__pulse': isHovering && item.path }"
                                                            position="center" cover height="237px" :src="item.imageUrl">
                                                        </v-img> -->
                                                    </div>
                                                    <div class="survey-content py-2">
                                                        <v-list-item class="px-6" height="60">
                                                            <template v-slot:title>
                                                                <span class="text-wrap font-weight-bold"> {{ item.title
                                                                    }}
                                                                </span>
                                                            </template>
                                                        </v-list-item>
                                                        <v-divider></v-divider>
                                                        <v-card-text class="text-medium-emphasis pa-6">
                                                            <div>{{ item.description }}</div>
                                                        </v-card-text>
                                                        <v-card-actions class="d-flex justify-center align-center px-8">
                                                            <v-spacer></v-spacer>
                                                            <router-link v-if="item.path" target="_blank"
                                                                class="text-decoration-none text-primary font-weight-medium"
                                                                :to="item.path ? item.path : '#'"> Ir a Formulario
                                                                <v-icon end>mdi-arrow-right</v-icon> </router-link>

                                                            <span v-else class="text-grey-lighten-1"> Ir a Formulario
                                                                <v-icon end>mdi-arrow-right</v-icon> </span>

                                                        </v-card-actions>
                                                    </div>
                                                </div>
                                            </template>
                                        </v-hover>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-tabs-window-item>

                        <v-tabs-window-item :value="2">
                            <v-card-text class="px-0">

                                <v-row>
                                    <v-col md="12">
                                        <div class="d-flex">
                                            <v-spacer></v-spacer>
                                            <v-spacer></v-spacer>
                                            <v-text-field variant="outlined" bg-color="white"
                                                placeholder="Buscar encuesta" density="compact">
                                                <template v-slot:append-inner>
                                                    <v-icon class="opacity-100" color="primary">mdi-magnify</v-icon>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </v-col>

                                    <v-col md="12">
                                        <v-table density="compact">
                                            <thead>
                                                <tr>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">
                                                        ID
                                                    </th>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">
                                                        NOMBRE
                                                    </th>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">
                                                        CORREO ELECTRÓNICO
                                                    </th>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">
                                                        ENCUESTA
                                                    </th>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">
                                                        FECHA DE CREACIÓN
                                                    </th>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">
                                                        FECHA DE MODIFICACIÓN
                                                    </th>
                                                    <th class="border-0 text-center bg-primary py-4 text-left">

                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th colspan="8"
                                                        style="background: #FCFCFC; height: 15px; border: 0 ;"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="item in desserts" :key="item.name">
                                                    <td>{{ item.calories }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td class="text-center">28/06/2024</td>
                                                    <td class="text-center">28/06/2024</td>
                                                    <td class="text-center">
                                                        <div class="d-flex justify-center">
                                                            <v-btn rounded="lg" style="border: 2px solid"
                                                                class="my-2 mx-1 font-weight-bold" height="35"
                                                                max-width="25" size="x-small" variant="outlined"
                                                                color="red-app">
                                                                <v-icon size="20">mdi-trash-can</v-icon>
                                                            </v-btn>
                                                            <v-btn rounded="lg" style="border: 2px solid"
                                                                class="my-2 mx-1 font-weight-bold" height="35"
                                                                max-width="25" size="x-small" variant="outlined"
                                                                color="primary">
                                                                <v-icon size="20">mdi-file-edit-outline</v-icon>
                                                            </v-btn>
                                                            <v-btn rounded="lg" style="border: 2px solid"
                                                                class="my-2 mx-1 font-weight-bold" height="35"
                                                                max-width="25" size="x-small" variant="outlined"
                                                                color="secondary">
                                                                <v-icon size="20">mdi-eye-outline</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-table>


                                    </v-col>
                                    <v-col cols="8" offset="4" class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-pagination :total-visible="4" v-model="page" variant="flat" border="1"
                                            active-color="secondary" density="compact"
                                            :length="totalPage"></v-pagination>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-tabs-window-item>

                    </v-tabs-window>
                </v-col>
            </v-row>
        </v-card-text>
    </v-container>

</template>
<script>
import validationFields from '../functions/validationFields';
export default {
    mixins: [validationFields],
    name: 'SurveysAdmin',
    data() {
        return {
            searchTerm: "", // Termino de búsqueda

            tab: null,
            page: 1,
            totalPage: 5,
            cantPerPage: 15,
            desserts: [
                {
                    name: 'Frozen Yogurt',
                    calories: 159,
                    status: "Activo"
                },
                {
                    name: 'Ice cream sandwich',
                    calories: 237,
                    status: "Inactivo"
                },
                {
                    name: 'Eclair',
                    calories: 262,
                    status: "Activo"
                },
                {
                    name: 'Cupcake',
                    calories: 305,
                    status: "Activo"
                },
                {
                    name: 'Gingerbread',
                    calories: 356,
                    status: "Activo"
                },
                {
                    name: 'Jelly bean',
                    calories: 375,
                    status: "Inactivo"
                },
                {
                    name: 'Lollipop',
                    calories: 392,
                    status: "Activo"
                },
                {
                    name: 'Honeycomb',
                    calories: 408,
                    status: "Activo"
                },
                {
                    name: 'Donut',
                    calories: 452,
                    status: "Activo"
                },
                {
                    name: 'KitKat',
                    calories: 518,
                    status: "Activo"
                }

            ],
            surveys: [
                {
                    title: 'Formulario de Oferta PST',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor.',
                    icon: 'mdi-map-marker-outline',
                    path: '/formularios/oferta-pst',
                    imageUrl: 'https://img.freepik.com/fotos-premium/hombre-muy-feliz-despues-hacer-comercio-exitoso-mercado-valores_1218867-180654.jpg',
                },
                {
                    title: 'Formulario Oferta Para Guías',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor.',
                    icon: 'mdi-map-marker-outline',
                    path: '/formularios/ofertas-guias',
                    imageUrl: 'https://img.freepik.com/foto-gratis/amigos-explorando-mapa-ciudad_23-2147846902.jpg',
                },
                {
                    title: 'Formulario de Empleabilidad',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante venenatis.',
                    icon: 'mdi-hotel',
                    path: '/formularios/empleabilidad',
                    imageUrl: 'https://img.freepik.com/fotos-premium/pareja-adulta-alcanza-acuerdo-agente-bienes-raices-oficina-mujeres-estrechan-mano_926199-4111764.jpg',
                },
                {
                    title: 'Formulario Registro de Visitantes',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras justo odio, dapibus ac facilisis.',
                    icon: 'mdi-bus',
                    path: '/formularios/registro-visitantes',
                    imageUrl: 'https://img.freepik.com/foto-gratis/cerrar-documento-firma-mano-lapiz_23-2149080815.jpg',
                },
                {
                    title: 'Formulario Ingreso de Visitantes',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras justo odio, dapibus ac facilisis.',
                    icon: 'mdi-bus',
                    path: '/formularios/ingreso-visitantes',
                    imageUrl: 'https://img.freepik.com/foto-gratis/turistas-ciudad-mirando-mapa_23-2147641191.jpg',
                },
                // {
                //     title: 'Percepción Turista Atractivos',
                //     description: 'Enlaces para copiar y codigo QR para escanear, Enlaces para copiar y codigo QR para escanear',
                //     icon: 'mdi-bus',
                //     path: '/formularios/percepcion-turista-atractivo',
                //     imageUrl: 'https://img.freepik.com/fotos-premium/mujer-cara-viejas-fotos_223509-12696.jpg',
                // },
                // {
                //     title: 'Percepción Turista Alojamiento',
                //     description: 'Enlaces para copiar y codigo QR para escanear, Enlaces para copiar y codigo QR para escanear',
                //     icon: 'mdi-bus',
                //     path: '/formularios/percepcion-turista-alojamiento',
                //     imageUrl: 'https://img.freepik.com/foto-gratis/resumen-borroso-dormitorio-sala-estar-interior-fondo-borroso-foto_74190-9910.jpg',
                // },
                
            ],
            filteredSurveys: [],
        }
    },
   
    watch: {
        searchTerm: {
            immediate: false,
            handler() {
                if (this.filteredSurveys.length > 0) {
                    const interval = setInterval(() => {
                        if (this.filteredSurveys.length > 0) {
                            // Encuentra el último índice del elemento que no incluye searchTerm
                            const indexToRemove = this.filteredSurveys
                                .map((survey, index) => ({ survey, index }))
                                .reverse() // Revertir el array para empezar desde el final
                                .find(({ survey }) => !survey.title.toLowerCase().includes(this.searchTerm.toLowerCase()))?.index;

                            if (indexToRemove !== undefined) {
                                this.filteredSurveys.splice(indexToRemove, 1); // Elimina el elemento en el índice encontrado
                            } else {
                                clearInterval(interval); // Detiene el intervalo cuando todos los elementos han sido revisados
                                // Cuando el array esté vacío, repoblamos filteredSurveys con los elementos filtrados
                                this.filteredSurveys = this.surveys.filter((survey) =>
                                    survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
                                );
                            }
                        } else {
                            clearInterval(interval); // Detiene el intervalo cuando el array está vacío
                            // Cuando el array esté vacío, repoblamos filteredSurveys con los elementos filtrados
                            this.filteredSurveys = this.surveys.filter((survey) =>
                                survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
                            );
                        }
                    }, 200); // Ajusta el intervalo según tus necesidades
                } else {
                    // Si filteredSurveys ya está vacío, repoblamos directamente
                    this.filteredSurveys = this.surveys.filter((survey) =>
                        survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
                    );
                }
            }
        },
    },
    mounted() {
        this.filteredSurveys = JSON.parse(JSON.stringify(this.surveys));
    },
    created() {
    },
    methods: {


        combineClasses(isHovering, item) {
            // Obtén las clases de getAnimationClasses
            const animationClasses = this.getAnimationClasses();

            // Combina las clases condicionales con las clases de animación
            return [
                {
                    'elevation-3': isHovering && item.path,
                    'elevation-1': !isHovering || !item.path,
                    'opacity-40': !item.path,
                },
                animationClasses // Aquí se agrega la cadena de clases de getAnimationClasses
            ];
        }

    }
}
</script>

<style>
.survey-card {
    display: flex;
    background-color: white;
    overflow: hidden;
}

.survey-card .survey-image {
    flex: 1;
}

.survey-card .survey-content {
    flex: 1.5;
}
</style>