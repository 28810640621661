<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation2
      v-if="isMdOrLower"
      color="primary"
      title="Percepción del Destino"
      subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es 
                muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de 
                identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos 
                unas preguntas adicionales sobre su llegada al departamento."
    >
    </NavBarAnimation2>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: fixed; height: 100%"
      >
        <LeftPanelAnimation2
          title="Percepción del"
          subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es 
                muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de 
                identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos 
                unas preguntas adicionales sobre su llegada al departamento."
          :wordsAnimate="['Destino']"
        >
        </LeftPanelAnimation2>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9" class="ml-auto">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Tipo de documento de identificación</span
                        >
                        <span class="font-weight-bold">*</span>
                  

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.items_type_doc"
                            :items="formItems.itemsDocumentType"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                            return-object
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw"
                                      :model-value="isActive"
                                      v-model="form.items_type_doc"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsDocumentType.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                      </v-col>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Número de documento de identificación</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[validations.required, validations.maxLength(50)]"
                          v-model="form.field_f_document_number"
                          color="primary"
                          variant="underlined"
                          v-numbers-only-app
                          placeholder=""
                          @blur="getAutocompleteFormRegistroVisitantes"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">¿Cuál fue su sexo al nacer?</span>
                        <span class="font-weight-bold">*</span>
                        {{form.items_gender}}
                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.items_gender"
                            :items="formItems.itemsGenderAtBirth"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.items_gender"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsGenderAtBirth.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                      </v-col>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuántos años cumplidos tiene?</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[validations.required, validations.maxLength(3)]"
                          v-model="form.field_h_age_completed"
                          color="primary"
                          variant="underlined"
                          v-numbers-only-app
                          placeholder=""
                        ></v-text-field>
                      </v-col>

                      <v-col md="4" cols="12" class="py-1">
                        <span class="font-weight-medium">País</span>
                        <v-select
                          v-model="form.field_h_country_residence"
                          :rules="[validations.required]"
                          color="primary"
                          variant="underlined"
                           item-value="id"
                            item-title="name"
                          :items="formItems.itemsCountryOfResidence"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col md="4" cols="12" class="py-1">
                        <span class="font-weight-medium">Departamento/Estado</span>
                        <v-select
                          v-model="form.field_h_state_residence"
                          :rules="[validations.required]"
                          color="primary"
                          variant="underlined"
                           item-value="id"
                            item-title="name"
                          :items="formItems.itemsStateOfResidence"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col md="4" cols="12" class="py-1">
                        <span class="font-weight-medium">Ciudad</span>
                        <v-select
                          v-model="form.field_h_city_residence"
                          :rules="[validations.required]"
                          color="primary"
                          variant="underlined"
                           item-value="id"
                            item-title="name"
                          :items="formItems.itemsCityOfResidence"
                          return-object
                        ></v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es el nivel educativo más alto alcanzado?</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.items_educational_level"
                            :items="formItems.itemsEducationLevel"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                            return-object
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw"
                                      :model-value="isActive"
                                      v-model="form.items_educational_level"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsEducationLevel.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                      </v-col>
                      <v-col md="6" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Correo electrónico de contacto</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[
                            validations.required,
                            validations.maxLength(255),
                            validations.emailFormat,
                          ]"
                          v-model="form.field_h_contact_email"
                          color="primary"
                          variant="underlined"
                          placeholder=""
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12" class="py-1">
                        <span class="font-weight-bold">Teléfono de contacto</span>
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[validations.required, validations.maxLength(255)]"
                          v-model="form.field_h_contact_phone"
                          color="primary"
                          variant="underlined"
                          v-numbers-only-app
                          placeholder=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Tipo de Alojamiento Utilizado?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_h_accommodation_type"
                            :items="formItems.itemsAccommodationType"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_h_accommodation_type"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsAccommodationType.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                       
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Indique el tipo de viaje de acuerdo con el grupo de personas
                          que lo acompañan?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_type_of_trip_group"
                            :items="formItems.itemsTypeOfTripGroup"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_type_of_trip_group.length - 2 }}
                                {{
                                  form.field_d_type_of_trip_group.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsTypeOfTripGroup.length"
                              ></v-divider>
                            </template>
                          </v-select>

                      
                      </v-col>

                      <v-col md="12" cols="12" class="py-1"  v-if="
                            form.field_d_type_of_trip_group.length > 0 &&
                            !form.field_d_type_of_trip_group.includes(427) &&
                            !form.field_d_type_of_trip_group.includes(428)
                          ">
                        <span class="font-weight-bold"
                          >¿El grupo de viaje incluye niños?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_d_includes_children"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsIncludesChildren"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_d_includes_children ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep6">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿De qué forma se enteró usted del departamento del Guaviare
                          como destino turístico?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_discovered_guaviare"
                            :items="formItems.itemsDiscoveredGuaviare"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_discovered_guaviare.length - 2 }}
                                {{
                                  form.field_d_discovered_guaviare.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsDiscoveredGuaviare.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                      </v-col>
                      <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_d_discovered_guaviare.includes(457)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            placeholder="¿Cuál?"
                            v-model="form.field_d_discovery_method"
                            :rules="[validations.required]"
                          ></v-text-field>
                        </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep7">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál fue el motivo principal para viajar al departamento del
                          Guaviare?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_main_reason_for_travel"
                            :items="formItems.itemsMainReasonForTravel"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_main_reason_for_travel.length - 2 }}
                                {{
                                  form.field_d_main_reason_for_travel.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsMainReasonForTravel.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        

                        
                      </v-col>

                      <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_d_main_reason_for_travel.includes(445)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            v-model="form.field_d_reason_for_travel"
                            :rules="[validations.required]"
                            placeholder="¿Cuál es el motivo?"
                          ></v-text-field>
                        </v-col>

                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Por qué medio de transporte llegó/llegaron al
                          departamento?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_transportation_method"
                            :items="formItems.itemsTransportationMethod"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_transportation_method.length - 2 }}
                                {{
                                  form.field_d_transportation_method.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsTransportationMethod.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                    
                    
                      </v-col>
                      <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_d_transportation_method.includes(465)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            placeholder="¿Cuál?"
                            v-model="form.field_d_transport_method"
                            :rules="[validations.required]"
                          ></v-text-field>
                        </v-col>

                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep8">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuáles de los municipios visitó desde el momento que llegó al
                          Guaviare?</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_h_municipalities_visited"
                            :items="formItems.itemsVisitedMunicipalitiesGuaviare"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_h_municipalities_visited.length - 2 }}
                                {{
                                  form.field_h_municipalities_visited.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsVisitedMunicipalitiesGuaviare.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep9">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Qué actividades realizó en el Guaviare?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_h_activities_done"
                            :items="formItems.itemsActivitiesInGuaviare"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_h_activities_done.length - 2 }}
                                {{
                                  form.field_h_activities_done.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsActivitiesInGuaviare.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                        </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep10">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Cuál es su nivel de percepción de los establecimientos de
                          alimentación, teniendo en cuenta que 5
                          <v-icon color="orange-lighten-1">mdi-star</v-icon> es "Muy
                          satisfecho" y 1 "Muy insatisfecho"?</span
                        >
                        <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in ratingsPerception"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="ratings_perception_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>

                      <!-- <v-row>
                        <template v-for="(item, index) in ratingsPerception" :key="index">
                          <v-col
                            md="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center"
                          >
                            <span class="">{{ item.label }}</span>
                          </v-col>
                          <v-col
                            md="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex justify-center align-center"
                          >
                            <v-rating
                              v-model="item.value"
                              active-color="orange-lighten-1"
                              color="orange-lighten-1"
                            ></v-rating>
                          </v-col>
                        </template>
                      </v-row> -->


                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="11">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep11">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál fue el valor de los gastos del viaje por persona y por
                          día?</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[validations.required, validations.maxLength(255)]"
                          v-model="form.field_h_expenses_per_person_per_day"
                          color="primary"
                          variant="underlined"
                          v-numbers-only-app
                          placeholder=""
                        ></v-text-field>

                        <ul class="my-3 text-caption">
                          <li>
                            <span class="text-secondary font-weight-medium"
                              >Ayuda 1:</span
                            >
                            Para calcular el gasto del viaje no debes incluir el valor de
                            los tiquetes aéreos/buses de terminal ni los valores pagados
                            por el hospedaje, incluye los valores adicionales que hayas
                            pagado en el destino por recreación, alimentación, souvenirs,
                            entre otros.
                          </li>
                          <li>
                            <span class="text-secondary font-weight-medium"
                              >Ayuda 2:</span
                            >
                            Si viajas solo, divide el total de tus gastos en el total de
                            días que te quedaste en el departamento.
                          </li>
                          <li>
                            <span class="text-secondary font-weight-medium"
                              >Ayuda 3:</span
                            >
                            Si viajas con acompañantes y tu fuiste quién pagó todo el
                            viaje (para las personas que viajan contigo, por ejemplo,
                            familias), debes dividir este valor en el número de días y en
                            el número de personas.
                          </li>
                        </ul>
                      </v-col>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Del 100% de lo que gastó en este viaje, indique qué porcentaje
                          gastó en cada uno de los siguientes ítems</span
                        >
                        <span class="font-weight-bold">*</span>

                        <div :class="{ 'two-columns-div': !isMdOrLower }">
                          <v-row
                            v-for="(item, index) in itemsPorcentajeGastos"
                            :key="index"
                            class="d-flex align-center py-0 ma-0"
                          >
                            <v-col md="6" cols="12" class="py-1">
                              <span class="">{{ item.label }}</span>
                            </v-col>
                            <v-col md="6" cols="12" class="py-1">
                              <v-text-field
                                :rules="[validations.required, validations.maxLength(3)]"
                                v-model="form[item.model]"
                                color="primary"
                                variant="underlined"
                                v-numbers-only-app
                                placeholder=""
                              >
                                <template v-slot:append-inner>
                                  <span class="font-weight-bold">%</span>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>

                        <ul class="mt-3 text-caption">
                          <li>
                            <span class="text-secondary font-weight-medium"
                              >Ayuda 1:</span
                            >
                            Si compró paquetes turísticos todo incluido y no incurrio en
                            gastos adicionales por alimentación, entradas a atractivos o
                            alimentación, digite 0 en estos tres últimos conceptos e
                            introduzca todo dentro de paquete turístico.
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="12">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep12">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          Cuál es su nivel de percepción del Departamento en general, de
                          acuerdo a los siguientes criterios, teniendo en cuenta que 5
                          <v-icon color="orange-lighten-1">mdi-star</v-icon> es "Muy
                          satisfecho" y 1 "Muy insatisfecho"?</span
                        >
                        <v-list style="width: 100%">
                          <v-list-item class="px-0">
                              <v-list-item-title class="text-wrap"> </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  Sin servicio/No utilizó
                                </div>
                              </template>
                            </v-list-item>
                            <v-list-item
                              v-for="(item, index) in ratingsPerceptionDepartamento"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  <v-checkbox
                                    v-model="form[item.model]"
                                    value="NA"
                                    color="primary"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </template>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="ratings_perception_departamento_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>

                      <!-- <v-row>
                        <template
                          v-for="(item, index) in ratingsPerceptionDepartamento"
                          :key="index"
                        >
                          <v-col
                            sm="12"
                            md="6"
                            cols="12"
                            class="py-1 d-flex align-center"
                          >
                            <span class="">{{ item.label }}</span>
                          </v-col>
                          <v-col
                            sm="12"
                            md="6"
                            cols="12"
                            class="py-1 d-flex align-center justify-center"
                          >
                            <v-rating
                              v-model="item.value"
                              active-color="orange-lighten-1"
                              color="orange-lighten-1"
                            ></v-rating>
                          </v-col>
                        </template>
                      </v-row> -->
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="13">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep13">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Usted fue informado por parte de alguna persona o institución,
                          acerca de la oferta de naturaleza (fauna, flora, actividades de
                          aventura), agroturismo o cultura del Departamento?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_h_informed_about_nature_offer"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsInformedAboutNatureOffer"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_h_informed_about_nature_offer ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                      </v-col>

                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Qué instituciones o personas le brindaron información?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_h_institutions_provided_info"
                            :items="formItems.itemsInstitutionsProvidedInfo"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_h_institutions_provided_info.length - 2 }}
                                {{
                                  form.field_h_institutions_provided_info.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsInstitutionsProvidedInfo.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                      
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="14">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep14">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Cuál es su nivel de percepción respecto al acceso a los
                          productos turísticos del destino, teniendo en cuenta que 5
                          <v-icon color="orange-lighten-1">mdi-star</v-icon> es "Muy
                          difícil" y 1 "Muy fácil"?</span
                        >

                        <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in ratingsPerceptionAccesoProd"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="ratings_perception_acceso_prod_required"
                            :rules="[validations.required]"
                          >
                          </v-input>

                        <!-- <v-row
                          v-for="(item, index) in ratingsPerceptionAccesoProd"
                          :key="index"
                          class="d-flex align-center py-0 ma-0"
                        >
                          <v-col md="6" cols="12" class="py-1">
                            <span class="">{{ item.label }}</span>
                          </v-col>
                          <v-col md="6" cols="12" class="py-1">
                            <v-rating
                              v-model="item.value"
                              active-color="orange-lighten-1"
                              color="orange-lighten-1"
                            ></v-rating>
                          </v-col>
                        </v-row> -->
                      </v-col>

                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Recomendaría el destino a amigos, familiares, seguidores en
                          redes sociales u otros conocidos?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_h_would_recommend_destination"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsRecommendDestination"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_h_would_recommend_destination ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="15">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep15">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Deseas seguir recibiendo información acerca del destino
                          Guaviare y su oferta turística?</span
                        >
                        <span class="font-weight-bold">*</span>

                        <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_h_continue_receiving_info"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsContinueReceivingInfoGuaviare"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_h_continue_receiving_info ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                      </v-col>

                      <v-col md="6" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Fecha de la llegada al departamento</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-menu :close-on-content-click="false" v-model="menuDateCheckin">
                          <template v-slot:activator="{ props }">
                            <v-text-field
                              :rules="[validations.required]"
                              rounded="lg"
                              v-model="form.field_h_arrival_date"
                              append-inner-icon="mdi-calendar-month"
                              readonly
                              v-bind="props"
                              variant="underlined"
                            ></v-text-field>
                          </template>
                          <VueDatePicker
                            class=""
                            v-model="form.date_checkin"
                            locale="es"
                            :dark="false"
                            :enable-time-picker="false"
                            inline
                            auto-apply
                          />
                        </v-menu>
                      </v-col>
                      <v-col md="6" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Fecha de salida del departamento</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-menu
                          :close-on-content-click="false"
                          v-model="menuDateCheckout"
                        >
                          <template v-slot:activator="{ props }">
                            <v-text-field
                              :rules="[validations.required]"
                              rounded="lg"
                              v-model="form.field_h_departure_date"
                              append-inner-icon="mdi-calendar-month"
                              readonly
                              v-bind="props"
                              variant="underlined"
                            ></v-text-field>
                          </template>
                          <VueDatePicker
                            class=""
                            v-model="form.date_checkout"
                            locale="es"
                            :dark="false"
                            :enable-time-picker="false"
                            inline
                            auto-apply
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation2 from "./includes/LeftPanelAnimation2.vue";
import NavBarAnimation2 from "./includes/NavBarAnimation2.vue";
import UserExperience from "./includes/UserExperience.vue";
import moment from "moment";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormDestinyPerception",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation2,
    NavBarAnimation2,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      form_relations_question: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,
      formConfig: null,
      formItems: {
        itemsDocumentType: [],
        itemsGenderAtBirth: [],
        itemsCountryOfResidence: [],
        itemsStateOfResidence: [],
        itemsCityOfResidence: [],
        itemsEducationLevel: [],
        itemsAccommodationType: [],
        itemsTypeOfTripGroup: [],
        itemsIncludesChildren: [],
        itemsDiscoveredGuaviare: [],
        itemsMainReasonForTravel: [],
        itemsTransportationMethod: [],
        itemsVisitedMunicipalitiesGuaviare: [],
        itemsActivitiesInGuaviare: [],
        itemsInformedAboutNatureOffer: [],
        itemsInstitutionsProvidedInfo: [],
        itemsRecommendDestination: [],
        itemsContinueReceivingInfoGuaviare: [],
      },
      menuDateCheckin: false,
      menuDateCheckout: false,
      form: {
        items_type_doc: null,
        field_f_document_number: null,
        items_gender: null,
        field_h_age_completed: null,
        field_h_country_residence: null,
        field_h_state_residence: null,
        field_h_city_residence: null,
        field_h_education_level: null,
        field_h_contact_email: null,
        field_h_contact_phone: null,
        field_h_accommodation_type: null,
        field_d_type_of_trip_group: [],
        field_d_includes_children: null,
        field_d_discovered_guaviare: [],
        field_d_discovery_method: null,
        field_d_main_reason_for_travel: [],
        field_d_reason_for_travel: null,
        field_d_transportation_method: [],
        field_d_transport_method: null,
        field_h_municipalities_visited: [],
        field_h_activities_done: [],
        field_h_food_variety_menu: null,
        field_h_food_menu_consumed: null,
        field_h_food_attention_time: null,
        field_h_food_hygiene_cleanliness: null,
        field_h_food_quality_price: null,
        field_h_food_staff_treatment: null,
        field_h_expenses_per_person_per_day: null,
        field_h_spent_tourist_attractions: null,
        field_h_spent_food_drinks: null,
        field_h_spent_internal_transportation: null,
        field_h_spent_personal_health_goods: null,
        field_h_spent_cultural_recreational_service: null,
        field_h_spent_souvenirs: null,
        field_h_spent_tour_package: null,
        field_h_spent_others: null,
        field_h_road_state_national: null,
        field_h_road_state_municipal: null,
        field_h_safety_perception: null,
        field_h_satisfaction_overall_visit: null,
        field_h_informed_about_nature_offer: null,
        field_h_institutions_provided_info: [],
        field_h_tourist_products_access: null,
        field_h_would_recommend_destination: null,
        field_h_continue_receiving_info: null,
        field_h_arrival_date: null,
        field_h_departure_date: null,
      },

    


      ratings_perception_required: null,
      ratingsPerception: [
        { model: 'field_h_food_variety_menu', label: "Variedad del Menú", value: 0 },
        { model: 'field_h_food_menu_consumed', label: "Menú consumido", value: 0 },
        { model: 'field_h_food_attention_time', label: "Tiempo de atención", value: 0 },
        { model: 'field_h_food_hygiene_cleanliness', label: "Aseo e higiene de las instalaciones gastronómicas", value: 0 },
        { model: 'field_h_food_quality_price', label: "Relación calidad precio", value: 0 },
        { model: 'field_h_food_staff_treatment', label: "Trato del personal del restaurante", value: 0 },
      ],

      ratings_perception_departamento_required: null,
      ratingsPerceptionDepartamento: [
        { model: 'field_h_road_state_national', 
          label: "Estado de la carretera nacional (vía para llegar al departamento)",
          value: 0,
        },
        {  model: 'field_h_road_state_municipal', label: "Estado de las vias internas de los municipios", value: 0 },
        {  model: 'field_h_safety_perception', label: "Seguridad del destino", value: 0 },
        {  model: 'field_h_satisfaction_overall_visit', label: "Satisfacción de la visita en general al Departamento", value: 0 },
      ],
      
      ratings_perception_acceso_prod_required : null,
      ratingsPerceptionAccesoProd: [{ model : 'field_h_tourist_products_access', label: "Acceso a productos turísticos", value: 0 }],

      itemsPorcentajeGastos: [
        { model: 'field_h_spent_tourist_attractions', label: "Atractivos Turísticos", value: null },
        { model: 'field_h_spent_food_drinks', label: "Alimentación (A&B)", value: null },
        { model: 'field_h_spent_internal_transportation', label: "Transporte interno dentro del departamento", value: null },
        { model: 'field_h_spent_personal_health_goods', label: "Bienes de uso personal y salud", value: null },
        { model: 'field_h_spent_cultural_recreational_service', label: "Servicio cultural y recreacional", value: null },
        { model: 'field_h_spent_souvenirs', label: "Compras (Souvenirs, artesanías)", value: null },
        { model: 'field_h_spent_tour_package', label: "Paquete turístico", value: null },
        { model: 'field_h_spent_others', label: "Otros", value: null },
      ],



      isMobile: false,

      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      stepFormServiceOrder: 1,
      stepTitles: [
        {
          group: 1,
          label: "Información del Visitante",
          id: 1,
          title: "Identificación",
          show: true,
        },
        {
          group: 1,
          label: "Información del Visitante",
          id: 2,
          title: "Datos Personales y Residencia",
          show: true,
        },
        {
          group: 1,
          label: "Información del Visitante",
          id: 3,
          title: "Educación y Contacto",
          show: true,
        },
        {
          group: 1,
          label: "Información del Visitante",
          id: 4,
          title: "Alojamiento Utilizado",
          show: true,
        },
        {
          group: 2,
          label: "Información del Viaje",
          id: 5,
          title: "Detalles del Grupo de Viaje",
          show: true,
        },
        {
          group: 2,
          label: "Información del Viaje",
          id: 6,
          title: "Información sobre el Descubrimiento del Destino",
          show: true,
        },
        {
          group: 2,
          label: "Información del Viaje",
          id: 7,
          title: "Motivo y Medio de Transporte del Viaje",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 8,
          title: "Municipios Visitados",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 9,
          title: "Actividades en el Guaviare",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 10,
          title: "Establecimientos de Alimentación",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 11,
          title: "Valor de Gastos del Viaje",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 12,
          title: "Percepción General del Departamento",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 13,
          title: "Información Recibida sobre la Oferta del Departamento",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 14,
          title: "Acceso y Recomendación del Destino",
          show: true,
        },
        {
          group: 3,
          label: "Percepción Sobre el Destino",
          id: 15,
          title: "Información y Fechas de Viaje",
          show: true,
        },
      ],

      showIndicator: true, // Initially show the indicator
      activeWatchVisit: true
    };
  },
  watch: {

    "form.field_d_type_of_trip_group"() {
      this.form.field_d_includes_children = null;
    },
    'form.items_type_doc'() {
      this.getAutocompleteFormRegistroVisitantes();
    },
    // 'form.field_f_document_number'() {
    //   this.getAutocompleteFormRegistroVisitantes();
    // },

    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },

    "form.field_h_country_residence"() {
      if (this.activeWatchVisit) {
        this.formItems.itemsStateOfResidence = [];
        this.formItems.itemsCityOfResidence = [];

        this.form.field_h_state_residence = null;
        this.form.field_h_city_residence = null;
      }

      if (this.form.field_h_country_residence) {
        this.getDepartments(this.form.field_h_country_residence.id);
      }
    },

    "form.field_h_state_residence"() {
      if (this.activeWatchVisit) {
        this.formItems.itemsCityOfResidence = [];
        this.form.field_h_city_residence = null;
      }
      if (this.form.field_h_state_residence) {
        this.getCities(this.form.field_h_state_residence.id);
      }
    },


    "form.date_checkin"() {
      if (this.form.date_checkin) {
        const fecha = moment(this.form.date_checkin);
        this.form.field_h_arrival_date = fecha.format("YYYY-MM-DD");
      } else {
        this.form.field_h_arrival_date = null;
      }
      this.menuDateCheckin = false;
    },
    "form.date_checkout"() {
      if (this.form.date_checkout) {
        const fecha = moment(this.form.date_checkout);
        this.form.field_h_departure_date = fecha.format("YYYY-MM-DD");
      } else {
        this.form.field_h_departure_date = null;
      }
      this.menuDateCheckout = false;
    },

    
    "form.field_h_food_variety_menu"(){
      this.validateRatingMenu();
    },
    "form.field_h_food_menu_consumed"(){
      this.validateRatingMenu();
    },
    "form.field_h_food_attention_time"(){
      this.validateRatingMenu();
    },
    "form.field_h_food_hygiene_cleanliness"(){
      this.validateRatingMenu();
    },
    "form.field_h_food_quality_price"(){
      this.validateRatingMenu();
    },
    "form.field_h_food_staff_treatment"(){
      this.validateRatingMenu();
    },

    "form.field_h_road_state_national"(){
      this.validateRatingDepartamento();
    },
    "form.field_h_road_state_municipal"(){
      this.validateRatingDepartamento();
    },
    "form.field_h_safety_perception"(){
      this.validateRatingDepartamento();
    },
    "form.field_h_satisfaction_overall_visit"(){
      this.validateRatingDepartamento();
    },

    "form.field_h_tourist_products_access"(){
      this.validateRatingAccesoProd();
    },

  },
  methods: {
    getAutocompleteFormRegistroVisitantes() {
      // Solo activamos la función si ambos campos tienen valores
      if (this.form.items_type_doc && this.form.field_f_document_number) {
        
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(
            url +
              `/select-option/get-autocomplete-form-registro-visitantes?tipo_doc=${this.form.items_type_doc.id}&numero_identificacion=${this.form.field_f_document_number}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            
            if(response && response.data && response.data.info_personal && response.data.info_personal.length > 0) {
              this.autocompleteInfoPerson(response.data.info_personal[0]);
            }

            if (response.data && response.data.other_info) {
              this.activeWatch = false;
              response.data.other_info.forEach((item) => {
                // Verifica si el campo existe en el formulario de manera segura
                if (Object.prototype.hasOwnProperty.call(this.form, item.field)) {
                  // Actualiza el campo del formulario con el valor correspondiente
                  this.form[item.field] = item.text_response || item.selected_options;
                }
              });

              setTimeout(() => {
                this.activeWatch = true;
              }, 1000);

            }
            
          })
          .catch((error) => {
            console.log(error);
          });

      }
    },

    autocompleteInfoPerson(data){
      if(data && data.response_details && data.response_details.length > 0){
        console.log(data.response_details[0]);
        
        var info_personal = data.response_details[0];
        if(info_personal && info_personal.text_response && info_personal.text_response.length > 0){

          this.activeWatchVisit = false;
          
          this.form.field_h_age_completed = info_personal.text_response[0].edad;
          this.form.items_gender = info_personal.text_response[0].sexo ? info_personal.text_response[0].sexo.id : null;
          this.form.field_h_country_residence = info_personal.text_response[0].procedencia ? info_personal.text_response[0].procedencia.pais : null;
          this.form.field_h_state_residence = info_personal.text_response[0].procedencia ? info_personal.text_response[0].procedencia.departamento : null;
          this.form.field_h_city_residence = info_personal.text_response[0].procedencia ? info_personal.text_response[0].procedencia.ciudad : null;

          this.form.items_educational_level = info_personal.text_response[0].nivel_educativo ? info_personal.text_response[0].nivel_educativo : null;
          this.form.field_h_contact_email = info_personal.text_response[0].correo_electronico ? info_personal.text_response[0].correo_electronico : null;
          this.form.field_h_contact_phone = info_personal.text_response[0].telefono ? info_personal.text_response[0].telefono : null;

          setTimeout(() => {
            this.activeWatchVisit = true;
          }, 1000);
        }

      }
        
      
    },

    getCountries() {
      let url = process.env.VUE_APP_API_URL;
      const token = this.$store.state.token;

      axios
        .get(url + `/select-option/get-countries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.formItems.itemsCountryOfResidence = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDepartments(country_id) {
      let url = process.env.VUE_APP_API_URL;
      const token = this.$store.state.token;

      axios
        .get(url + `/select-option/get-states?country_id=${country_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.formItems.itemsStateOfResidence = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCities(department_id) {
      let url = process.env.VUE_APP_API_URL;
      const token = this.$store.state.token;

      axios
        .get(url + `/select-option/get-cities?state_id=${department_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.formItems.itemsCityOfResidence = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validateRatingMenu() {
      if (
        this.form.field_h_food_variety_menu &&
        this.form.field_h_food_menu_consumed &&
        this.form.field_h_food_attention_time &&
        this.form.field_h_food_hygiene_cleanliness &&
        this.form.field_h_food_quality_price &&
        this.form.field_h_food_staff_treatment
      ) {
        this.ratings_perception_required = 1;
      } else {
        this.ratings_perception_required = null;
      }
    },

    validateRatingDepartamento(){

      if (
        this.form.field_h_road_state_national &&
        this.form.field_h_road_state_municipal &&
        this.form.field_h_safety_perception &&
        this.form.field_h_satisfaction_overall_visit
      ) {
        this.ratings_perception_departamento_required = 1;
      } else {
        this.ratings_perception_departamento_required = null;
      }

    },

    validateRatingAccesoProd(){

      if (
        this.form.field_h_tourist_products_access
      ) {
        this.ratings_perception_acceso_prod_required = 1;
      } else {
        this.ratings_perception_acceso_prod_required = null;
      }

    },


    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 9,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      this.form = {
        
        items_type_doc: null,
        field_f_document_number: null,
        items_gender: null,
        field_h_age_completed: null,
        field_h_country_residence: null,
        field_h_state_residence: null,
        field_h_city_residence: null,
        field_h_education_level: null,
        field_h_contact_email: null,
        field_h_contact_phone: null,
        field_h_accommodation_type: null,
        field_d_type_of_trip_group: [],
        field_d_includes_children: null,
        field_d_discovered_guaviare: [],
        field_d_discovery_method: null,
        field_d_main_reason_for_travel: [],
        field_d_reason_for_travel: null,
        field_d_transportation_method: [],
        field_d_transport_method: null,
        field_h_municipalities_visited: [],
        field_h_activities_done: [],
        field_h_food_variety_menu: null,
        field_h_food_menu_consumed: null,
        field_h_food_attention_time: null,
        field_h_food_hygiene_cleanliness: null,
        field_h_food_quality_price: null,
        field_h_food_staff_treatment: null,
        field_h_expenses_per_person_per_day: null,
        field_h_spent_tourist_attractions: null,
        field_h_spent_food_drinks: null,
        field_h_spent_internal_transportation: null,
        field_h_spent_personal_health_goods: null,
        field_h_spent_cultural_recreational_service: null,
        field_h_spent_souvenirs: null,
        field_h_spent_tour_package: null,
        field_h_spent_others: null,
        field_h_road_state_national: null,
        field_h_road_state_municipal: null,
        field_h_safety_perception: null,
        field_h_satisfaction_overall_visit: null,
        field_h_informed_about_nature_offer: null,
        field_h_institutions_provided_info: [],
        field_h_tourist_products_access: null,
        field_h_would_recommend_destination: null,
        field_h_continue_receiving_info: null,
        field_h_arrival_date: null,
        field_h_departure_date: null,
      };

      this.stepFormServiceOrder = 1;
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/9`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              
              const items_type_doc = this.formConfig.questions.find(
                (q) => q.v_model === "items_type_doc"
              );
              if (items_type_doc) {
                this.formItems.itemsDocumentType = items_type_doc.options;
              }

              const items_gender = this.formConfig.questions.find(
                (q) => q.v_model === "items_gender"
              );
              if (items_gender) {
                this.formItems.itemsGenderAtBirth = items_gender.options;
              }

              const field_h_country_residence = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_country_residence"
              );
              if (field_h_country_residence) {
                this.getCountries();
              }

       

              const items_educational_level = this.formConfig.questions.find(
                (q) => q.v_model === "items_educational_level"
              );
              if (items_educational_level) {
                this.formItems.itemsEducationLevel = items_educational_level.options;
              }

              const field_h_accommodation_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_accommodation_type"
              );
              if (field_h_accommodation_type) {
                this.formItems.itemsAccommodationType = field_h_accommodation_type.options;
              }

              const field_d_type_of_trip_group = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_type_of_trip_group"
              );
              if (field_d_type_of_trip_group) {
                this.formItems.itemsTypeOfTripGroup = field_d_type_of_trip_group.options;
              }

              const field_d_includes_children = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_includes_children"
              );
              if (field_d_includes_children) {
                this.formItems.itemsIncludesChildren = field_d_includes_children.options;
              }

              const field_d_discovered_guaviare = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_discovered_guaviare"
              );
              if (field_d_discovered_guaviare) {
                this.formItems.itemsDiscoveredGuaviare =
                  field_d_discovered_guaviare.options;
              }

              const field_d_main_reason_for_travel = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_main_reason_for_travel"
              );
              if (field_d_main_reason_for_travel) {
                this.formItems.itemsMainReasonForTravel =
                  field_d_main_reason_for_travel.options;
              }

              const field_d_transportation_method = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_transportation_method"
              );
              if (field_d_transportation_method) {
                this.formItems.itemsTransportationMethod =
                  field_d_transportation_method.options;
              }

              const field_h_municipalities_visited = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_municipalities_visited"
              );
              if (field_h_municipalities_visited) {
                this.formItems.itemsVisitedMunicipalitiesGuaviare =
                  field_h_municipalities_visited.options;
              }

              const field_h_activities_done = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_activities_done"
              );
              if (field_h_activities_done) {
                this.formItems.itemsActivitiesInGuaviare =
                  field_h_activities_done.options;
              }

              const field_h_informed_about_nature_offer = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_informed_about_nature_offer"
              );
              if (field_h_informed_about_nature_offer) {
                this.formItems.itemsInformedAboutNatureOffer =
                  field_h_informed_about_nature_offer.options;
              }

              const field_h_institutions_provided_info = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_institutions_provided_info"
              );
              if (field_h_institutions_provided_info) {
                this.formItems.itemsInstitutionsProvidedInfo =
                  field_h_institutions_provided_info.options;
              }

              const field_h_would_recommend_destination = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_would_recommend_destination"
              );
              if (field_h_would_recommend_destination) {
                this.formItems.itemsRecommendDestination =
                  field_h_would_recommend_destination.options;
              }

              const field_h_continue_receiving_info = this.formConfig.questions.find(
                (q) => q.v_model === "field_h_continue_receiving_info"
              );
              if (field_h_continue_receiving_info) {
                this.formItems.itemsContinueReceivingInfoGuaviare =
                  field_h_continue_receiving_info.options;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    getAccommodationPlace() {
      /* this.formItems.itemsAccommodationPlace; */

      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/select-option/get-accommodations")
        .then((response) => {
          this.formItems.itemsAccommodationPlace = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    toggleActividadesCamara(activity) {
      const index = this.selectActividadesCamara.indexOf(activity);
      if (index === -1) {
        this.selectActividadesCamara.push(activity);
      } else {
        this.selectActividadesCamara.splice(index, 1);
      }
    },

    toggleActividadesOfrecidas(activity) {
      const index = this.selectActividadesOfrecidas.indexOf(activity);
      if (index === -1) {
        this.selectActividadesOfrecidas.push(activity);
      } else {
        this.selectActividadesOfrecidas.splice(index, 1);
      }
    },

    toggleServicioAdicional(activity) {
      const index = this.selectServicioAdicional.indexOf(activity);
      if (index === -1) {
        this.selectServicioAdicional.push(activity);
      } else {
        this.selectServicioAdicional.splice(index, 1);
      }
    },

    toggleGremiosAsociaciones(activity) {
      const index = this.selectGremiosAsociaciones.indexOf(activity);
      if (index === -1) {
        this.selectGremiosAsociaciones.push(activity);
      } else {
        this.selectGremiosAsociaciones.splice(index, 1);
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },
    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },

  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
  margin: 0;
}

.two-columns-div {
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  display: grid;
}
</style>
