<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation1
      v-if="isMdOrLower"
      title="Formulario Gestión de Empleabilidad"
      subTitle="Apreciado empresario, en esta
                        sección registrará los
                        datos de empleabilidad de su empresa. Recuerde que estos datos no serán utilizados
                        en ningún momento para realizar control fiscal o de impuestos, sino que nos ayudan a
                        caracterizar la empleabilidad generada en el sector turismo en temporadas altas y
                        bajas."
    ></NavBarAnimation1>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: sticky"
      >
        <LeftPanelAnimation1
          title="Formulario gestión de"
          subTitle="Apreciado empresario, en esta
                        sección registrará los
                        datos de empleabilidad de su empresa. Recuerde que estos datos no serán utilizados
                        en ningún momento para realizar control fiscal o de impuestos, sino que nos ayudan a
                        caracterizar la empleabilidad generada en el sector turismo en temporadas altas y
                        bajas."
          :wordsAnimate="['Empleabilidad']"
        ></LeftPanelAnimation1>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">NIT</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(50)]"
                            v-model="form.field_a_company_nit"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">DV</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(50)]"
                            v-model="form.field_a_company_dv"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el nombre del representante legal de la
                            empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_rep_name"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Nombre del Establecimiento</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_name_estab"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Dirección</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_company_address"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Email </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.emailFormat]"
                            v-model="form.field_a_company_email"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Registro Nacional de Turismo (RNT)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_national_tourism_reg"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Categoría de RNT</span>
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_rnt_category"
                            :items="formItems.itemsRntCategory"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_a_rnt_category"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsRntCategory.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Señale las actividades económicas registradas en Cámara de
                            Comercio de este establecimiento</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_registered_activities"
                            :items="formItems.itemsRegisteredActivities"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_registered_activities.length - 2 }}
                                {{
                                  form.field_a_registered_activities.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsRegisteredActivities.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                          <!-- si en array selectActivities contiene el valor agregamos la clase selected-check al div -->
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique el número de empleados contratados el año anterior,
                            de acuerdo con la forma de la vinculación laboral que tienen
                            (debe tener al menos un dato en toda la matriz)</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-table density="compact" class="mt-2">
                            <thead v-if="!isMobile">
                              <tr>
                                <th></th>
                                <th
                                  class="text-caption text-center font-weight-bold"
                                  colspan="2"
                                >
                                  Colaboradores de la empresa
                                </th>
                                <th
                                  class="text-caption text-center font-weight-bold"
                                  colspan="2"
                                >
                                  Dueño y/o socios que trabajan en la empresa
                                </th>
                                <th></th>
                              </tr>
                              <tr>
                                <th class="text-caption text-center font-weight-bold">
                                  Cargos / Tipo de Vinculación
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Hombres
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Mujeres
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Hombres
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Mujeres
                                </th>
                                <th
                                  class="text-caption text-center font-weight-bold text-red"
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="(row, index) in rowsTable1" :key="index">
                                <template v-if="!isMobile">
                                  <td class="text-caption">{{ row.label }}</td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[0].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">{{
                                      calculateColumnSuma(rowsTable1, 0)
                                    }}</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[1].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">{{
                                      calculateColumnSuma(rowsTable1, 1)
                                    }}</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[2].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">{{
                                      calculateColumnSuma(rowsTable1, 2)
                                    }}</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[3].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">{{
                                      calculateColumnSuma(rowsTable1, 3)
                                    }}</span>
                                  </td>
                                  <td class="text-center">
                                    <span v-if="row.fields">{{
                                      calculateRowSuma(row.fields)
                                    }}</span>
                                    <span v-else-if="row.label == 'Total'">
                                      {{
                                        (calculateColumnSuma(rowsTable1, 0) +
                                          calculateColumnSuma(rowsTable1, 1) +
                                          calculateColumnSuma(rowsTable1, 2) +
                                          calculateColumnSuma(rowsTable1, 3)) *
                                        2
                                      }}
                                    </span>
                                  </td>
                                </template>
                                <template v-else>
                                  <td :colspan="6" v-if="row.label != 'Total'">
                                    <v-card class="py-3" elevation="0">
                                      <v-list>
                                        <v-list-item>
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Cargos / Tipo de
                                            Vinculación:</v-list-item-title
                                          >
                                          <v-list-item-subtitle
                                            class="text-caption text-wrap"
                                            >{{ row.label }}</v-list-item-subtitle
                                          >
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Hombres (Colaboradores de la
                                            empresa):</v-list-item-title
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            v-model="form[row.fields[0].model]"
                                            v-numbers-only-app
                                          ></v-text-field>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Mujeres (Colaboradores de la
                                            empresa):</v-list-item-title
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            v-model="form[row.fields[1].model]"
                                            v-numbers-only-app
                                          ></v-text-field>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Hombres (Dueño y/o socios que trabajan en la
                                            empresa):</v-list-item-title
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            v-model="form[row.fields[2].model]"
                                            v-numbers-only-app
                                          ></v-text-field>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Mujeres (Dueño y/o socios que trabajan en la
                                            empresa):</v-list-item-title
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            v-model="form[row.fields[3].model]"
                                            v-numbers-only-app
                                          ></v-text-field>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Total:</v-list-item-title
                                          >
                                          <span>{{ calculateRowSuma(row.fields) }}</span>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                  </td>
                                </template>
                              </tr>
                              <tr v-if="isMobile">
                                <td colspan="6">
                                  <v-row>
                                    <v-col xs="6" sm="6" md="3" cols="12"
                                      ><p class="font-weight-bold text-caption">
                                        Total Colaboradores Hombres:
                                      </p>
                                      {{ calculateColumnSuma(rowsTable1, 0) }}</v-col
                                    >
                                    <v-col xs="6" sm="6" md="3" cols="12"
                                      ><p class="font-weight-bold text-caption">
                                        Total Colaboradores Mujeres:
                                      </p>
                                      {{ calculateColumnSuma(rowsTable1, 1) }}</v-col
                                    >
                                    <v-col xs="6" sm="6" md="3" cols="12"
                                      ><p class="font-weight-bold text-caption">
                                        Total Socios Hombres:
                                      </p>
                                      {{ calculateColumnSuma(rowsTable1, 2) }}</v-col
                                    >
                                    <v-col xs="6" sm="6" md="3" cols="12"
                                      ><p class="font-weight-bold text-caption">
                                        Total Socios Mujeres:
                                      </p>
                                      {{ calculateColumnSuma(rowsTable1, 3) }}</v-col
                                    >
                                  </v-row>
                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Alguno de los cargos directivos/gerenciales de la empresa
                            (Gerente, Administrador, Coordinador) durante el año anterior
                            fue ocupado por mujeres?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_c_women_in_leadership_last_year"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsWomenInLeadershipLastYear"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_c_women_in_leadership_last_year == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuántos de los empleados del año anterior pertenecen a los
                            siguientes grupos poblacionales? (Recuerde que un individuo
                            puede ser clasificado en 2 o más grupos poblacionales, por
                            ejemplo, indigenas de 18 a 28 años)</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-table density="compact" class="mt-2">
                            <thead v-if="!isMobile">
                              <tr>
                                <th class="text-caption text-center font-weight-bold">
                                  Cargos / Tipo de Vinculación
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Grupos étnicos
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Víctimas Conflicto
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Jóvenes (18 a 28 años)
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Firmantes de paz
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Discapacidad
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  LGBTIQ+
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Ninguno
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="(row, index) in rowsTable2" :key="index">
                                <template v-if="!isMobile">
                                  <td class="text-caption">{{ row.label }}</td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[0].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[1].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[2].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[3].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[4].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[5].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-if="row.label != 'Total'"
                                      v-model="form[row.fields[6].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                    <span v-if="row.label == 'Total'">0</span>
                                  </td>
                                </template>
                                <template v-else>
                                  <td :colspan="6">
                                    <v-card class="py-3" elevation="0">
                                      <v-row>
                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Cargos / Tipo de
                                            Vinculación:</v-list-item-title
                                          >
                                          <v-list-item-subtitle
                                            class="text-caption text-wrap"
                                            >{{ row.label }}</v-list-item-subtitle
                                          >
                                        </v-col>
                                      </v-row>
                                      <v-row style="display: ruby">
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Grupos étnicos:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[0].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Víctimas Conflicto:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[1].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Jóvenes (18 a 28 años):</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[2].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Firmantes de paz:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[3].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption">Discapacidad:</span>
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[4].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption">LGBTIQ+:</span>
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[5].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <span class="text-caption">Ninguno:</span>
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[6].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Del total de empleados directos del año anterior, indique el
                            número de empleados en cada casilla acuerdo con el valor del
                            salario mensual que pagó? (incluya comisiones, bonificaciones
                            y pagos extralaborales)</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-table density="compact" class="mt-2">
                            <thead v-if="!isMobile">
                              <tr>
                                <th class="text-caption text-center font-weight-bold">
                                  Cargos / Tipo de Vinculación
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Menos de 1 SMMLV
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  1 SMMLV
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de 1 SMMLV-Hasta 2 SMMLV
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de 2 SMMLV-Hasta 4 SMMLV
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de 4 SMMLV
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Total
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="(row, index) in rowsTable3" :key="index">
                                <template v-if="!isMobile">
                                  <td class="text-caption">{{ row.label }}</td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[0].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[1].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[2].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[3].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[4].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    {{ calculateRowSuma(row.fields) }}
                                  </td>
                                </template>
                                <template v-else>
                                  <td :colspan="6">
                                    <v-card class="py-3" elevation="0">
                                      <v-row>
                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Cargos / Tipo de
                                            Vinculación:</v-list-item-title
                                          >
                                          <v-list-item-subtitle
                                            class="text-caption text-wrap"
                                            >{{ row.label }}</v-list-item-subtitle
                                          >
                                        </v-col>
                                      </v-row>
                                      <v-row style="display: ruby">
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Menos de 1 SMMLV:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[0].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption">1 SMMLV:</span>
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[1].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Más de 1 SMMLV-Hasta 2 SMMLV:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[2].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Más de 2 SMMLV-Hasta 4 SMMLV:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[3].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <span class="text-caption"
                                            >Más de 4 SMMLV:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[4].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <span class="text-caption">Total:</span>
                                          <br />
                                          {{ calculateRowSuma(row.fields) }}
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep9">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Del total de empleados indirectos del año anterior, indique
                            el número de empleados en cada casilla acuerdo con el valor
                            del pago diario?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-table density="compact" class="mt-2">
                            <thead v-if="!isMobile">
                              <tr>
                                <th class="text-caption text-center font-weight-bold">
                                  Cargos / Tipo de Vinculación
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  $50.000 o menos
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de $50.000 hasta $100.000
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de $100.000 hasta $150.000
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de $150.000 hasta $200.000
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Más de $200.000
                                </th>
                                <th class="text-caption text-center font-weight-bold">
                                  Total
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="(row, index) in rowsTable4" :key="index">
                                <template v-if="!isMobile">
                                  <td class="text-caption">{{ row.label }}</td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[0].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[1].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[2].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[3].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      color="primary"
                                      variant="outlined"
                                      density="compact"
                                      v-model="form[row.fields[4].model]"
                                      v-numbers-only-app
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                  <td class="text-center">
                                    {{ calculateRowSuma(row.fields) }}
                                  </td>
                                </template>
                                <template v-else>
                                  <td :colspan="6">
                                    <v-card class="py-3" elevation="0">
                                      <v-row>
                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <v-list-item-title
                                            class="text-caption text-wrap"
                                            >Cargos / Tipo de
                                            Vinculación:</v-list-item-title
                                          >
                                          <v-list-item-subtitle
                                            class="text-caption text-wrap"
                                            >{{ row.label }}</v-list-item-subtitle
                                          >
                                        </v-col>
                                      </v-row>
                                      <v-row style="display: ruby">
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >$50.000 o menos:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[0].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Más de $50.000 hasta $100.000:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[1].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Más de $100.000 hasta $150.000:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[2].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="6" md="6" cols="6" class="py-1">
                                          <span class="text-caption"
                                            >Más de $150.000 hasta $200.000:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[3].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>

                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <span class="text-caption"
                                            >Más de $200.000:</span
                                          >
                                          <v-text-field
                                            color="primary"
                                            variant="outlined"
                                            density="compact"
                                            v-model="form[row.fields[4].model]"
                                            v-numbers-only-app
                                            hide-details
                                          ></v-text-field>
                                        </v-col>
                                        <v-col sm="12" md="12" cols="12" class="py-1">
                                          <span class="text-caption">Total:</span>
                                          <br />
                                          {{ calculateRowSuma(row.fields) }}
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Indique la cantidad de empleados (directos e indirectos) del
                            año anterior que manejan un segundo idioma extranjero
                            (ejemplo: inglés)
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_bilingual_employees_previous_year"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Indique los idiomas extranjeros que usted o su equipo de
                            personal directo o indirecto conoce
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_foreign_languages_known"
                            :items="formItems.itemsForeignLanguagesKnown"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_c_foreign_languages_known.length - 2 }}
                                {{
                                  form.field_c_foreign_languages_known.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsForeignLanguagesKnown.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="11">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep11">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            De las siguientes afirmaciones, marque las que son verdaderas
                            para su empresa
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_true_company_statements"
                            :items="formItems.itemsTrueCompanyStatements"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_c_true_company_statements.length - 2 }}
                                {{
                                  form.field_c_true_company_statements.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsTrueCompanyStatements.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_c_true_company_statements.includes(333)"
                        >
                          <span class="font-weight-bold">
                            ¿Cuántos Gías Certificados?
                          </span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_certified_guides_count"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_c_true_company_statements.includes(334)"
                        >
                          <span class="font-weight-bold">
                            ¿Cuántos Gías <b>NO</b> Certificados?
                          </span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_non_certified_guides_count"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="12">
                  <v-card-text class="text-body-2">
                    <span class="font-weight-bold">
                      ¿Digite la cantidad de empleados (directos e indirectos) del año
                      anterior, según el último nivel educativo alcanzado?
                    </span>
                    <span class="font-weight-bold">*</span>

                    <v-form ref="formStep12">
                      <v-row>
                        <v-col md="6" cols="12" class="py-1">
                          <v-row>
                            <v-col md="6" cols="12" class="py-1"> </v-col>
                            <v-col md="6" cols="12" class="py-1 text-center">
                              <span class="font-weight-bold">Cantidad de empleados</span>
                            </v-col>
                          </v-row>
                          <v-row v-for="(item, index) in rowsEmpleados1" :key="index">
                            <v-col md="6" cols="12" class="py-1 d-flex align-center">
                              {{ item.label }}
                            </v-col>
                            <v-col md="6" cols="12" class="py-1">
                              <v-text-field
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-model="form[item.model]"
                                v-numbers-only-app
                                density="compact"
                                color="primary"
                                variant="underlined"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <v-row>
                            <v-col md="6" cols="12" class="py-1"> </v-col>
                            <v-col md="6" cols="12" class="py-1 text-center">
                              <span class="font-weight-bold">Cantidad de empleados</span>
                            </v-col>
                          </v-row>
                          <v-row v-for="(item, index) in rowsEmpleados2" :key="index">
                            <v-col md="6" cols="12" class="py-1 d-flex align-center">
                              {{ item.label }}
                            </v-col>
                            <v-col md="6" cols="12" class="py-1">
                              <v-text-field
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-model="form[item.model]"
                                v-numbers-only-app
                                density="compact"
                                color="primary"
                                variant="underlined"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="13">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep13">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Cuántos de sus empleados (directos e indirectos) son personas
                          originarias, nacidas o propias del Departamento del Guaviare?
                        </span>
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[validations.required, validations.maxLength(255)]"
                          v-model="form.field_c_employees_born_in_guaviare"
                          v-numbers-only-app
                          color="primary"
                          variant="underlined"
                          placeholder=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="14">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep14">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Qué documentos exigio durante el año anterior para la
                          contratacion de sus empleados?
                        </span>
                        <span class="font-weight-bold">*</span>

                        <v-select
                          :menu-props="{ 'max-width': '100%' }"
                          v-model="form.field_c_documents_required_for_hiring"
                          :items="formItems.itemsDocumentsRequiredForHiring"
                          :rules="[validations.checkboxRequired]"
                          item-value="id"
                          item-title="text"
                          multiple
                          variant="underlined"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{
                                item.title && item.title.length > 20
                                  ? item.title.substring(0, 20) + "..."
                                  : item.title
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="text-grey text-caption align-self-center"
                            >
                              (+{{
                                form.field_c_documents_required_for_hiring.length - 2
                              }}
                              {{
                                form.field_c_documents_required_for_hiring.length - 2 == 1
                                  ? "opción"
                                  : "opciones"
                              }})
                            </span>
                          </template>

                          <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" class="text-caption-font">
                              <template v-slot:prepend="{ isActive }">
                                <v-list-item-action start>
                                  <v-checkbox-btn
                                    color="primary"
                                    :true-value="true"
                                    :model-value="isActive"
                                  ></v-checkbox-btn>
                                </v-list-item-action>
                              </template>

                              <v-list-item-title class="text-wrap text-caption">
                                <span class="text-caption">{{ item.raw.title }} </span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index + 1 <
                                formItems.itemsDocumentsRequiredForHiring.length
                              "
                            ></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="15">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep15">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Qué obligaciones cumplio como empleador durante el año pasado?
                        </span>
                        <span class="font-weight-bold">*</span>

                        <v-select
                          :menu-props="{ 'max-width': '100%' }"
                          v-model="form.field_c_employer_obligations_met"
                          :items="formItems.itemsEmployerObligationsMet"
                          :rules="[validations.checkboxRequired]"
                          item-value="id"
                          item-title="text"
                          multiple
                          variant="underlined"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{
                                item.title && item.title.length > 20
                                  ? item.title.substring(0, 20) + "..."
                                  : item.title
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="text-grey text-caption align-self-center"
                            >
                              (+{{ form.field_c_employer_obligations_met.length - 2 }}
                              {{
                                form.field_c_employer_obligations_met.length - 2 == 1
                                  ? "opción"
                                  : "opciones"
                              }})
                            </span>
                          </template>

                          <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" class="text-caption-font">
                              <template v-slot:prepend="{ isActive }">
                                <v-list-item-action start>
                                  <v-checkbox-btn
                                    color="primary"
                                    :true-value="true"
                                    :model-value="isActive"
                                  ></v-checkbox-btn>
                                </v-list-item-action>
                              </template>

                              <v-list-item-title class="text-wrap text-caption">
                                <span class="text-caption">{{ item.raw.title }} </span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index + 1 < formItems.itemsEmployerObligationsMet.length
                              "
                            ></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="16">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep16">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Qué documentos exigió durante el año anterior para la
                          contratacion de personas jurídicas (es decir, cuando contrata a
                          otras empresas)?
                        </span>
                        <span class="font-weight-bold">*</span>

                        <v-select
                          :menu-props="{ 'max-width': '100%' }"
                          v-model="form.field_c_documents_required_for_company_hiring"
                          :items="formItems.itemsDocumentsRequiredForCompanyHiring"
                          :rules="[validations.checkboxRequired]"
                          item-value="id"
                          item-title="text"
                          multiple
                          variant="underlined"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{
                                item.title && item.title.length > 20
                                  ? item.title.substring(0, 20) + "..."
                                  : item.title
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="text-grey text-caption align-self-center"
                            >
                              (+{{
                                form.field_c_documents_required_for_company_hiring
                                  .length - 2
                              }}
                              {{
                                form.field_c_documents_required_for_company_hiring
                                  .length -
                                  2 ==
                                1
                                  ? "opción"
                                  : "opciones"
                              }})
                            </span>
                          </template>

                          <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" class="text-caption-font">
                              <template v-slot:prepend="{ isActive }">
                                <v-list-item-action start>
                                  <v-checkbox-btn
                                    color="primary"
                                    :true-value="true"
                                    :model-value="isActive"
                                  ></v-checkbox-btn>
                                </v-list-item-action>
                              </template>

                              <v-list-item-title class="text-wrap text-caption">
                                <span class="text-caption">{{ item.raw.title }} </span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index + 1 <
                                formItems.itemsDocumentsRequiredForCompanyHiring.length
                              "
                            ></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="17">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep17">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          Indique los meses del año anterior, en el que requirió contratar
                          más empleados (directos o indirectos)
                        </span>
                        <span class="font-weight-bold">*</span>
                        <div>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_high_demand_months_last_year"
                            :items="formItems.itemsHighDemandMonthsLastYear"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{
                                  form.field_c_high_demand_months_last_year.length - 2
                                }}
                                {{
                                  form.field_c_high_demand_months_last_year.length - 2 ==
                                  1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 <
                                  formItems.itemsHighDemandMonthsLastYear.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="18">
                  <v-card-text class="text-body-2">
                    <span class="font-weight-bold">
                      ¿Por favor indique el número de empleados (directos e indirectos)
                      que contrató el año anterior para las siguientes temporadas?
                    </span>
                    <span class="font-weight-bold">*</span>

                  <v-form ref="formStep18">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <div class="mt-3">
                          <v-row
                            v-for="(item, index) in rowNumeroEmpleadosTemporadas"
                            :key="index"
                          >
                            <v-col md="6" cols="12" class="py-1 d-flex align-center">
                              {{ item.label }}
                            </v-col>
                            <v-col md="6" cols="12" class="py-1">
                              <v-text-field
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-model="form[item.model]"
                                v-numbers-only-app
                                density="compact"
                                color="primary"
                                variant="underlined"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="19">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep19">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Qué acciones implementa para garantizar la seguridad de sus empleados y clientes?
                        </span>
                        <span class="font-weight-bold">*</span>

                        <v-select
                          :menu-props="{ 'max-width': '100%' }"
                          v-model="form.field_c_employee_security_measures"
                          :items="formItems.itemsEmployeeSecurityMeasures"
                          :rules="[validations.checkboxRequired]"
                          item-value="id"
                          item-title="text"
                          multiple
                          variant="underlined"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{
                                item.title && item.title.length > 20
                                  ? item.title.substring(0, 20) + "..."
                                  : item.title
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="text-grey text-caption align-self-center"
                            >
                              (+{{ form.field_c_employee_security_measures.length - 2 }}
                              {{
                                form.field_c_employee_security_measures.length - 2 == 1
                                  ? "opción"
                                  : "opciones"
                              }})
                            </span>
                          </template>

                          <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" class="text-caption-font">
                              <template v-slot:prepend="{ isActive }">
                                <v-list-item-action start>
                                  <v-checkbox-btn
                                    color="primary"
                                    :true-value="true"
                                    :model-value="isActive"
                                  ></v-checkbox-btn>
                                </v-list-item-action>
                              </template>

                              <v-list-item-title class="text-wrap text-caption">
                                <span class="text-caption">{{ item.raw.title }} </span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index + 1 < formItems.itemsEmployeeSecurityMeasures.length
                              "
                            ></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="20">
                  <v-card-text class="text-body-2">
                  <v-form ref="formStep20">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">
                          ¿Qué acciones o medidas toma para promover el bienestar de las
                          personas trabajadoras de su empresa / emprendimiento?
                        </span>
                        <span class="font-weight-bold">*</span>

                        <v-select
                          :menu-props="{ 'max-width': '100%' }"
                          v-model="form.field_c_employee_wellbeing_measures"
                          :items="formItems.itemsEmployeeWellbeingMeasures"
                          :rules="[validations.checkboxRequired]"
                          item-value="id"
                          item-title="text"
                          multiple
                          variant="underlined"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{
                                item.title && item.title.length > 20
                                  ? item.title.substring(0, 20) + "..."
                                  : item.title
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="text-grey text-caption align-self-center"
                            >
                              (+{{ form.field_c_employee_wellbeing_measures.length - 2 }}
                              {{
                                form.field_c_employee_wellbeing_measures.length - 2 == 1
                                  ? "opción"
                                  : "opciones"
                              }})
                            </span>
                          </template>

                          <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" class="text-caption-font">
                              <template v-slot:prepend="{ isActive }">
                                <v-list-item-action start>
                                  <v-checkbox-btn
                                    color="primary"
                                    :true-value="true"
                                    :model-value="isActive"
                                  ></v-checkbox-btn>
                                </v-list-item-action>
                              </template>

                              <v-list-item-title class="text-wrap text-caption">
                                <span class="text-caption">{{ item.raw.title }} </span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index + 1 <
                                formItems.itemsEmployeeWellbeingMeasures.length
                              "
                            ></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation1 from "./includes/LeftPanelAnimation1.vue";
import NavBarAnimation1 from "./includes/NavBarAnimation1.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormEmployability",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation1,
    NavBarAnimation1,
    UserExperience,
    DialogResponse
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      dialogResponse: null,
      loadingPageOverlay: false,
      formItems: {
        itemsRntCategory: [],
        itemsRegisteredActivities: [],
        itemsWomenInLeadershipLastYear: [],
        itemsForeignLanguagesKnown: [],
        itemsTrueCompanyStatements: [],
        itemsDocumentsRequiredForHiring: [],
        itemsEmployerObligationsMet: [],
        itemsDocumentsRequiredForCompanyHiring: [],
        itemsHighDemandMonthsLastYear: [],
        itemsEmployeeSecurityMeasures: [],
        itemsEmployeeWellbeingMeasures: [],
      },

      form: {
        field_a_company_nit: null,
        field_a_company_dv: null,
        field_a_rep_name: null,
        field_a_name_estab: null,
        field_a_company_address: null,
        field_a_company_email: null,
        field_a_national_tourism_reg: null,
        field_a_rnt_category: null,
        field_a_registered_activities: [],
        field_c_male_employees_company_contract: 0,
        field_c_female_employees_company_contract: 0,
        field_c_male_owners_company_contract: 0,
        field_c_female_owners_company_contract: 0,
        field_c_male_employees_service_contract: 0,
        field_c_female_employees_service_contract: 0,
        field_c_male_owners_service_contract: 0,
        field_c_female_owners_service_contract: 0,
        field_c_male_employees_apprenticeship_contract: 0,
        field_c_female_employees_apprenticeship_contract: 0,
        field_c_male_owners_apprenticeship_contract: 0,
        field_c_female_owners_apprenticeship_contract: 0,
        field_c_male_employees_internship: 0,
        field_c_female_employees_internship: 0,
        field_c_male_owners_internship: 0,
        field_c_female_owners_internship: 0,
        field_c_male_employees_indirect_short_term: 0,
        field_c_female_employees_indirect_short_term: 0,
        field_c_male_owners_indirect_short_term: 0,
        field_c_female_owners_indirect_short_term: 0,
        field_c_male_employees_on_demand_services: 0,
        field_c_female_employees_on_demand_services: 0,
        field_c_male_owners_on_demand_services: 0,
        field_c_female_owners_on_demand_services: 0,
        field_c_male_employees_on_demand_guides: 0,
        field_c_female_employees_on_demand_guides: 0,
        field_c_male_owners_on_demand_guides: 0,
        field_c_female_owners_on_demand_guides: 0,
        field_c_women_in_leadership_last_year: null,
        field_c_ethnic_group_employees_company_contract: 0,
        field_c_conflict_victims_employees_company_contract: 0,
        field_c_youth_employees_company_contract: 0,
        field_c_peace_signatories_employees_company_contract: 0,
        field_c_disabled_employees_company_contract: 0,
        field_c_lgbtq_employees_company_contract: 0,
        field_c_no_specific_group_employees_company_contract: 0,
        field_c_ethnic_group_employees_service_contract: 0,
        field_c_conflict_victims_employees_service_contract: 0,
        field_c_youth_employees_service_contract: 0,
        field_c_peace_signatories_employees_service_contract: 0,
        field_c_disabled_employees_service_contract: 0,
        field_c_lgbtq_employees_service_contract: 0,
        field_c_no_specific_group_employees_service_contract: 0,
        field_c_ethnic_group_employees_apprenticeship_contract: 0,
        field_c_conflict_victims_employees_apprenticeship_contract: 0,
        field_c_youth_employees_apprenticeship_contract: 0,
        field_c_peace_signatories_employees_apprenticeship_contract: 0,
        field_c_disabled_employees_apprenticeship_contract: 0,
        field_c_lgbtq_employees_apprenticeship_contract: 0,
        field_c_no_specific_group_employees_apprenticeship_contract: 0,
        field_c_ethnic_group_employees_internship: 0,
        field_c_conflict_victims_employees_internship: 0,
        field_c_youth_employees_internship: 0,
        field_c_peace_signatories_employees_internship: 0,
        field_c_disabled_employees_internship: 0,
        field_c_lgbtq_employees_internship: 0,
        field_c_no_specific_group_employees_internship: 0,
        field_c_ethnic_group_employees_indirect_short_term: 0,
        field_c_conflict_victims_employees_indirect_short_term: 0,
        field_c_youth_employees_indirect_short_term: 0,
        field_c_peace_signatories_employees_indirect_short_term: 0,
        field_c_disabled_employees_indirect_short_term: 0,
        field_c_lgbtq_employees_indirect_short_term: 0,
        field_c_no_specific_group_employees_indirect_short_term: 0,
        field_c_ethnic_group_employees_on_demand_services: 0,
        field_c_conflict_victims_employees_on_demand_services: 0,
        field_c_youth_employees_on_demand_services: 0,
        field_c_peace_signatories_employees_on_demand_services: 0,
        field_c_disabled_employees_on_demand_services: 0,
        field_c_lgbtq_employees_on_demand_services: 0,
        field_c_no_specific_group_employees_on_demand_services: 0,
        field_c_ethnic_group_employees_on_demand_guides: 0,
        field_c_conflict_victims_employees_on_demand_guides: 0,
        field_c_youth_employees_on_demand_guides: 0,
        field_c_peace_signatories_employees_on_demand_guides: 0,
        field_c_disabled_employees_on_demand_guides: 0,
        field_c_lgbtq_employees_on_demand_guides: 0,
        field_c_no_specific_group_employees_on_demand_guides: 0,
        field_c_salary_less_than_1smmlv_company_contract: 0,
        field_c_salary_1smmlv_company_contract: 0,
        field_c_salary_1to2smmlv_company_contract: 0,
        field_c_salary_2to4smmlv_company_contract: 0,
        field_c_salary_more_than_4smmlv_company_contract: 0,
        field_c_salary_less_than_1smmlv_service_contract: 0,
        field_c_salary_1smmlv_service_contract: 0,
        field_c_salary_1to2smmlv_service_contract: 0,
        field_c_salary_2to4smmlv_service_contract: 0,
        field_c_salary_more_than_4smmlv_service_contract: 0,
        field_c_salary_less_than_1smmlv_apprenticeship_contract: 0,
        field_c_salary_1smmlv_apprenticeship_contract: 0,
        field_c_salary_1to2smmlv_apprenticeship_contract: 0,
        field_c_salary_2to4smmlv_apprenticeship_contract: 0,
        field_c_salary_more_than_4smmlv_apprenticeship_contract: 0,
        field_c_salary_less_than_1smmlv_internship: 0,
        field_c_salary_1smmlv_internship: 0,
        field_c_salary_1to2smmlv_internship: 0,
        field_c_salary_2to4smmlv_internship: 0,
        field_c_salary_more_than_4smmlv_internship: 0,
        field_c_daily_payment_less_than_50000_indirect: 0,
        field_c_daily_payment_50000to100000_indirect: 0,
        field_c_daily_payment_100000to150000_indirect: 0,
        field_c_daily_payment_150000to200000_indirect: 0,
        field_c_daily_payment_more_than_200000_indirect: 0,
        field_c_daily_payment_less_than_50000_on_demand_services: 0,
        field_c_daily_payment_50000to100000_on_demand_services: 0,
        field_c_daily_payment_100000to150000_on_demand_services: 0,
        field_c_daily_payment_150000to200000_on_demand_services: 0,
        field_c_daily_payment_more_than_200000_on_demand_services: 0,
        field_c_daily_payment_less_than_50000_on_demand_guides: 0,
        field_c_daily_payment_50000to100000_on_demand_guides: 0,
        field_c_daily_payment_100000to150000_on_demand_guides: 0,
        field_c_daily_payment_150000to200000_on_demand_guides: 0,
        field_c_daily_payment_more_than_200000_on_demand_guides: 0,
        field_c_bilingual_employees_previous_year: null,
        field_c_foreign_languages_known: [],
        field_c_true_company_statements: [],
        field_c_certified_guides_count: null,
        field_c_non_certified_guides_count: null,
        field_c_no_education_count: "0",
        field_c_primary_incomplete_count: "0",
        field_c_primary_complete_count: "0",
        field_c_high_school_incomplete_count: "0",
        field_c_high_school_complete_count: "0",
        field_c_technician_no_degree_count: "0",
        field_c_technician_with_degree_count: "0",
        field_c_professional_no_degree_count: "0",
        field_c_professional_with_degree_count: "0",
        field_c_postgrad_no_degree_count: "0",
        field_c_postgrad_with_degree_count: "0",
        field_c_employees_born_in_guaviare: null,
        field_c_documents_required_for_hiring: [],
        field_c_employer_obligations_met: [],
        field_c_documents_required_for_company_hiring: [],
        field_c_high_demand_months_last_year: [],
        field_c_employees_low_season: '0',
        field_c_employees_easter: '0',
        field_c_employees_mid_year_vacations: '0',
        field_c_employees_school_break: '0',
        field_c_employees_end_year_vacations: '0',
        field_c_employees_holiday_weekends: '0',
        field_c_employee_security_measures: [],
        field_c_employee_wellbeing_measures: [],
      },

      rowsTable1: [
        {
          label: "Contrato laboral con la empresa",
          fields: [
            { model: "field_c_male_employees_company_contract", value: null },
            { model: "field_c_female_employees_company_contract", value: null },
            { model: "field_c_male_owners_company_contract", value: null },
            { model: "field_c_female_owners_company_contract", value: null },
          ],
        },
        {
          label: "Prestación de servicios (por más de un mes)",
          fields: [
            { model: "field_c_male_employees_service_contract", value: null },
            { model: "field_c_female_employees_service_contract", value: null },
            { model: "field_c_male_owners_service_contract", value: null },
            { model: "field_c_female_owners_service_contract", value: null },
          ],
        },
        {
          label: "Contrato de aprendizaje",
          fields: [
            { model: "field_c_male_employees_apprenticeship_contract", value: null },
            { model: "field_c_female_employees_apprenticeship_contract", value: null },
            { model: "field_c_male_owners_apprenticeship_contract", value: null },
            { model: "field_c_female_owners_apprenticeship_contract", value: null },
          ],
        },
        {
          label: "Pasante",
          fields: [
            { model: "field_c_male_employees_internship", value: null },
            { model: "field_c_female_employees_internship", value: null },
            { model: "field_c_male_owners_internship", value: null },
            { model: "field_c_female_owners_internship", value: null },
          ],
        },
        {
          label: "Indirectos (Menor a un mes/por temporada/Evento)",
          fields: [
            { model: "field_c_male_employees_indirect_short_term", value: null },
            { model: "field_c_female_employees_indirect_short_term", value: null },
            { model: "field_c_male_owners_indirect_short_term", value: null },
            { model: "field_c_female_owners_indirect_short_term", value: null },
          ],
        },
        {
          label: "Por demanda (Transporte, servicios de limpieza, técnico, etc.)",
          fields: [
            { model: "field_c_male_employees_on_demand_services", value: null },
            { model: "field_c_female_employees_on_demand_services", value: null },
            { model: "field_c_male_owners_on_demand_services", value: null },
            { model: "field_c_female_owners_on_demand_services", value: null },
          ],
        },
        {
          label: "Por demanda (Únicamente guianza)",
          fields: [
            { model: "field_c_male_employees_on_demand_guides", value: null },
            { model: "field_c_female_employees_on_demand_guides", value: null },
            { model: "field_c_male_owners_on_demand_guides", value: null },
            { model: "field_c_female_owners_on_demand_guides", value: null },
          ],
        },
        { label: "Total" },
      ],
      rowsTable2: [
        {
          label: "Contrato laboral con la empresa",
          fields: [
            { model: "field_c_ethnic_group_employees_company_contract", value: null },
            { model: "field_c_conflict_victims_employees_company_contract", value: null },
            { model: "field_c_youth_employees_company_contract", value: null },
            {
              model: "field_c_peace_signatories_employees_company_contract",
              value: null,
            },
            { model: "field_c_disabled_employees_company_contract", value: null },
            { model: "field_c_lgbtq_employees_company_contract", value: null },
            {
              model: "field_c_no_specific_group_employees_company_contract",
              value: null,
            },
          ],
        },
        {
          label: "Prestación de servicios (por más de un mes)",
          fields: [
            { model: "field_c_ethnic_group_employees_service_contract", value: null },
            { model: "field_c_conflict_victims_employees_service_contract", value: null },
            { model: "field_c_youth_employees_service_contract", value: null },
            {
              model: "field_c_peace_signatories_employees_service_contract",
              value: null,
            },
            { model: "field_c_disabled_employees_service_contract", value: null },
            { model: "field_c_lgbtq_employees_service_contract", value: null },
            {
              model: "field_c_no_specific_group_employees_service_contract",
              value: null,
            },
          ],
        },
        {
          label: "Contrato de aprendizaje",
          fields: [
            {
              model: "field_c_ethnic_group_employees_apprenticeship_contract",
              value: null,
            },
            {
              model: "field_c_conflict_victims_employees_apprenticeship_contract",
              value: null,
            },
            { model: "field_c_youth_employees_apprenticeship_contract", value: null },
            {
              model: "field_c_peace_signatories_employees_apprenticeship_contract",
              value: null,
            },
            { model: "field_c_disabled_employees_apprenticeship_contract", value: null },
            { model: "field_c_lgbtq_employees_apprenticeship_contract", value: null },
            {
              model: "field_c_no_specific_group_employees_apprenticeship_contract",
              value: null,
            },
          ],
        },
        {
          label: "Pasante",
          fields: [
            { model: "field_c_ethnic_group_employees_internship", value: null },
            { model: "field_c_conflict_victims_employees_internship", value: null },
            { model: "field_c_youth_employees_internship", value: null },
            { model: "field_c_peace_signatories_employees_internship", value: null },
            { model: "field_c_disabled_employees_internship", value: null },
            { model: "field_c_lgbtq_employees_internship", value: null },
            { model: "field_c_no_specific_group_employees_internship", value: null },
          ],
        },
        {
          label: "Indirectos (Menor a un mes/por temporada/Evento)",
          fields: [
            { model: "field_c_ethnic_group_employees_indirect_short_term", value: null },
            {
              model: "field_c_conflict_victims_employees_indirect_short_term",
              value: null,
            },
            { model: "field_c_youth_employees_indirect_short_term", value: null },
            {
              model: "field_c_peace_signatories_employees_indirect_short_term",
              value: null,
            },
            { model: "field_c_disabled_employees_indirect_short_term", value: null },
            { model: "field_c_lgbtq_employees_indirect_short_term", value: null },
            {
              model: "field_c_no_specific_group_employees_indirect_short_term",
              value: null,
            },
          ],
        },
        {
          label: "Por demanda (Transporte, servicios de limpieza, técnico, etc.)",
          fields: [
            { model: "field_c_ethnic_group_employees_on_demand_services", value: null },
            {
              model: "field_c_conflict_victims_employees_on_demand_services",
              value: null,
            },
            { model: "field_c_youth_employees_on_demand_services", value: null },
            {
              model: "field_c_peace_signatories_employees_on_demand_services",
              value: null,
            },
            { model: "field_c_disabled_employees_on_demand_services", value: null },
            { model: "field_c_lgbtq_employees_on_demand_services", value: null },
            {
              model: "field_c_no_specific_group_employees_on_demand_services",
              value: null,
            },
          ],
        },
        {
          label: "Por demanda (Únicamente guianza)",
          fields: [
            { model: "field_c_ethnic_group_employees_on_demand_guides", value: null },
            { model: "field_c_conflict_victims_employees_on_demand_guides", value: null },
            { model: "field_c_youth_employees_on_demand_guides", value: null },
            {
              model: "field_c_peace_signatories_employees_on_demand_guides",
              value: null,
            },
            { model: "field_c_disabled_employees_on_demand_guides", value: null },
            { model: "field_c_lgbtq_employees_on_demand_guides", value: null },
            {
              model: "field_c_no_specific_group_employees_on_demand_guides",
              value: null,
            },
          ],
        },
      ],
      rowsTable3: [
        {
          label: "Contrato laboral con la empresa",
          fields: [
            { model: "field_c_salary_less_than_1smmlv_company_contract", value: null },
            { model: "field_c_salary_1smmlv_company_contract", value: null },
            { model: "field_c_salary_1to2smmlv_company_contract", value: null },
            { model: "field_c_salary_2to4smmlv_company_contract", value: null },
            { model: "field_c_salary_more_than_4smmlv_company_contract", value: null },
          ],
        },
        {
          label: "Prestación de servicios",
          fields: [
            { model: "field_c_salary_less_than_1smmlv_service_contract", value: null },
            { model: "field_c_salary_1smmlv_service_contract", value: null },
            { model: "field_c_salary_1to2smmlv_service_contract", value: null },
            { model: "field_c_salary_2to4smmlv_service_contract", value: null },
            { model: "field_c_salary_more_than_4smmlv_service_contract", value: null },
          ],
        },
        {
          label: "Contrato de aprendizaje",
          fields: [
            {
              model: "field_c_salary_less_than_1smmlv_apprenticeship_contract",
              value: null,
            },
            { model: "field_c_salary_1smmlv_apprenticeship_contract", value: null },
            { model: "field_c_salary_1to2smmlv_apprenticeship_contract", value: null },
            { model: "field_c_salary_2to4smmlv_apprenticeship_contract", value: null },
            {
              model: "field_c_salary_more_than_4smmlv_apprenticeship_contract",
              value: null,
            },
          ],
        },
        {
          label: "Pasante",
          fields: [
            { model: "field_c_salary_less_than_1smmlv_internship", value: null },
            { model: "field_c_salary_1smmlv_internship", value: null },
            { model: "field_c_salary_1to2smmlv_internship", value: null },
            { model: "field_c_salary_2to4smmlv_internship", value: null },
            { model: "field_c_salary_more_than_4smmlv_internship", value: null },
          ],
        },
      ],

      rowsTable4: [
        {
          label: "Indirectos (Menor a un mes/por temporada/Evento)",
          fields: [
            { model: "field_c_daily_payment_less_than_50000_indirect", value: null },
            { model: "field_c_daily_payment_50000to100000_indirect", value: null },
            { model: "field_c_daily_payment_100000to150000_indirect", value: null },
            { model: "field_c_daily_payment_150000to200000_indirect", value: null },
            { model: "field_c_daily_payment_more_than_200000_indirect", value: null },
          ],
        },
        {
          label: "Por demanda (Transporte, servicio técnico, etc.)",
          fields: [
            {
              model: "field_c_daily_payment_less_than_50000_on_demand_services",
              value: null,
            },
            {
              model: "field_c_daily_payment_50000to100000_on_demand_services",
              value: null,
            },
            {
              model: "field_c_daily_payment_100000to150000_on_demand_services",
              value: null,
            },
            {
              model: "field_c_daily_payment_150000to200000_on_demand_services",
              value: null,
            },
            {
              model: "field_c_daily_payment_more_than_200000_on_demand_services",
              value: null,
            },
          ],
        },
        {
          label: "Por demanda (Guianza)",
          fields: [
            {
              model: "field_c_daily_payment_less_than_50000_on_demand_guides",
              value: null,
            },
            {
              model: "field_c_daily_payment_50000to100000_on_demand_guides",
              value: null,
            },
            {
              model: "field_c_daily_payment_100000to150000_on_demand_guides",
              value: null,
            },
            {
              model: "field_c_daily_payment_150000to200000_on_demand_guides",
              value: null,
            },
            {
              model: "field_c_daily_payment_more_than_200000_on_demand_guides",
              value: null,
            },
          ],
        },
      ],

      rowsEmpleados1: [
        { label: "Sin estudio", model: "field_c_no_education_count" },
        { label: "Primaria incompleta", model: "field_c_primary_incomplete_count" },
        { label: "Primaria completa", model: "field_c_primary_complete_count" },
        {
          label: "Bachillerato incompleto",
          model: "field_c_high_school_incomplete_count",
        },
        { label: "Bachillerato completo", model: "field_c_high_school_complete_count" },
        {
          label: "Técnico/Tecnológo sin titulo",
          model: "field_c_technician_no_degree_count",
        },
      ],
      rowsEmpleados2: [
        {
          label: "Técnico/Tecnológo con titulo",
          model: "field_c_technician_with_degree_count",
        },
        {
          label: "Profesional sin titulo",
          model: "field_c_professional_no_degree_count",
        },
        {
          label: "Profesional con titulo",
          model: "field_c_professional_with_degree_count",
        },
        {
          label: "Profesional con postgrado sin titulo",
          model: "field_c_postgrad_no_degree_count",
        },
        {
          label: "Profesional con postgrado con titulo",
          model: "field_c_postgrad_with_degree_count",
        },
      ],

      rowNumeroEmpleadosTemporadas: [
        {
          label: "Temporada baja (febrero a mayo y agosto a octubre)",
          model: "field_c_employees_low_season",
        },
        { label: "Vacaciones Mitad de año", model: "field_c_employees_easter" },
        {
          label: "Temporada de vacaciones de fin de año (noviembre, diciembre, enero)",
          model: "field_c_employees_mid_year_vacations",
        },
        { label: "Semana Santa", model: "field_c_employees_school_break" },
        {
          label: "Semana de receso estudiantil (octubre)",
          model: "field_c_employees_end_year_vacations",
        },
        {
          label: "Ferias y fiestas (noviembre, diciembre, enero)",
          model: "field_c_employees_holiday_weekends",
        },
      ],
      /*
      itemsDocumentosExigidos: [
        "Hoja de vida",
        "Certificados de estudios",
        "Certificados laborales",
        "Documentos de identificación",
        "Constancia de afiliación/pago de pensión",
        "Constancia de afiliación/pago de salud",
        "Copia del RUT",
        "Exámenes médicos de ingreso",
        "Certificación bancaria",
        "Permiso de permanencia (extranjeros)",
        "Certificados de antecedentes",
        "Ninguno",
      ],

      itemsObligacionesEmpleador: [
        "Pago de salarios",
        "Afiliación a salud, pensión y arl",
        "Vacaciones",
        "Prestaciones sociales (Primas y cesantias)",
        "Auxilio transporte (cuando aplica)",
        "Pago horas extra y recargos",
        "Pago de incapacidades",
        "Dotación (cuando aplica)",
        "Viáticos (cuando aplica)",
        "Reconocimiento de licencias laborales					",
        "Pago de licencias laborales (Maternidad, luto, sindical, calamidad, electoral)	",
      ],

      itemsDocumentosRequeridosPJ: [
        "Certificado existencia y representación legal",
        "Registro Único Tributario (RUT)",
        "Antecedentes representante legal y empresa (Fiscales, Judiciales, Disciplinarios, Medidas correctivas)",
        "Certificado seguridad social y parafiscales",
        "Certificación de experiencia",
        "Certificado REDAM (representante legal)",
        "Estados financieros (últimos 12 meses)",
        "Certificado de capacidad financiera y organizacional",
        "Certificados ambientales (Cuando aplica)",
      ],

      itemsMeses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],

      itemsLanguage: ["Inglés", "Francés", "Alemán", "Portugués", "Italiano", "Otro"],

      itemsAccionesSeguridad: [
        "Estrategia de seguridad y salud en el trabajo",
        "Plan de emergencias",
        "Programas de capacitación en primeros auxilios",
        "Simulacros de emergencia",
        "Dotación de elementos de seguridad (botiquín, extintores, radios comunicación, etc.)",
        "Inspección y mantenimiento a instalaciones eléctricas ",
        "Evaluación de riesgos y mantenimiento del edificio",
        "Señalización de emergencia",
        "Programas de capacitación en atención de emergencias",
        "Medición de incidentes",
      ],

      itemsMedidasBienestar: [
        "Equilibrio entre el trabajo y la vida familiar",
        "Mejora constante de los espacios de trabajo",
        "Proponer retos y objetivos",
        "Obtener reconocimiento continuo",
        "Formación constante",
        "Fomentar el buen ambiente laboral",
        "Promover la participación activa de los empleados",
        "Ofrecer las máximas facilidades para la comida",
        "Incentivar un estilo de vida saludable",
        "Fomentar una cultura de apoyo y colaboración",
        "Escuchar y responder a las necesidades de los empleados",
        "Actividades sociales, recreativas, benéficas o de integración.",
      ],

      selecteLanguage: [],
      selectAfirmations: [],
      selectDocumentosExigidos: [],
      selectObligacionesEmpleador: [],
      selectDocumentosRequeridosPJ: [],
      selectMeses: [],
      selectAccionesSeguridad: [],
      selectMedidasBienestar: [],

      */

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      // itemsActivities: [
      //   "Bar",
      //   "Restaurante y Gastrobares",
      //   "Alojamiento",
      //   "Agencias de viajes",
      //   "Transporte (Terminal transporte)",
      //   "Actividades culturales y eventos",
      // ],
      // selectActivities: [],
      drawerStep: true,
      stepFormServiceOrder: 1,
      marketplace: "",
      stepTitles: [
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 1,
          title: "Datos Básicos",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 2,
          title: "Ubicación y contacto",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 3,
          title: "Registro Nacional de Turismo",
          show: true,
        },
        {
          group: 1,
          label: "Información Profesional",
          id: 4,
          title: "Cámara de Comercio",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Empleabilidad",
          title: "Número de empleados",
          show: true,
        },
        {
          group: 2,
          id: 6,
          label: "Empleabilidad",
          title: "Diversidad de Género en Cargos",
          show: true,
        },
        {
          group: 2,
          id: 7,
          label: "Empleabilidad",
          title: "Grupos Poblacionales",
          show: true,
        },
        {
          group: 2,
          id: 8,
          label: "Empleabilidad",
          title: "Salario Mensual",
          show: true,
        },
        {
          group: 2,
          id: 9,
          label: "Empleabilidad",
          title: "Pago Diario",
          show: true,
        },
        {
          group: 2,
          id: 10,
          label: "Empleabilidad",
          title: "Idiomas Extranjeros",
          show: true,
        },
        {
          group: 2,
          id: 11,
          label: "Empleabilidad",
          title: "Información de Guías",
          show: true,
        },
        {
          group: 2,
          id: 12,
          label: "Empleabilidad",
          title: "Nivel Educativo",
          show: true,
        },
        {
          group: 2,
          id: 13,
          label: "Empleabilidad",
          title: "Empleabilidad Guaviare",
          show: true,
        },
        {
          group: 2,
          id: 14,
          label: "Empleabilidad",
          title: "Documentos Requeridos para Contratación de Empleados",
          show: true,
        },
        {
          group: 2,
          id: 15,
          label: "Empleabilidad",
          title: "Obligaciones Cumplidas como Empleador",
          show: true,
        },
        {
          group: 2,
          id: 16,
          label: "Empleabilidad",
          title: "Documentos Requeridos para Contratación de Personas Jurídicas",
          show: true,
        },
        {
          group: 2,
          id: 17,
          label: "Empleabilidad",
          title: "Meses con Mayor Contratación",
          show: true,
        },
        {
          group: 2,
          id: 18,
          label: "Empleabilidad",
          title: "Contratación por Temporada",
          show: true,
        },
        {
          group: 2,
          id: 19,
          label: "Empleabilidad",
          title: "Garantías de Seguridad de Empleados y Clientes",
          show: true,
        },
        {
          group: 2,
          id: 20,
          label: "Empleabilidad",
          title: "Bienestar de los Trabajadores",
          show: true,
        },
      ],

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },

    "form.field_c_true_company_statements"() {
      this.form.field_c_non_certified_guides_count = null;
      this.form.field_c_certified_guides_count = null;
    },
  },
  methods: {

    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 4,
          user_id: this.$store.state.user.id,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      //restablecer valores por defecto
      this.form = {
        field_a_doc_type: null,
        field_a_operating_docs_other: null,
        field_a_doc_number: null,
        field_a_rep_name: null,
        field_a_rep_sex: null,
        field_a_pop_group: [],
        field_a_cultural_identity: [],
        field_a_personal_phones: [],
        field_a_personal_email: null,
        field_c_active_tourism_registry: null,
        field_c_tourism_registry_number: null,
        field_c_active_professional_card: null,
        field_c_professional_card_number: null,
        field_c_currently_guiding: null,
        field_c_municipalities_guiding: [],
        field_c_different_profession: null,
        field_c_which_profession: null,
        field_c_max_educational_level: null,
        field_c_years_experience: null,
        field_c_months_experience: null,
        field_c_specialization: [],
        field_c_spanish_language: null,
        field_c_english_language: null,
        field_c_french_language: null,
        field_c_german_language: null,
        field_c_portuguese_language: null,
        field_c_italian_language: null,
        field_c_other_language: null,
        field_c_other_language_specify: null,
        field_c_avg_rate_spanish: null,
        field_c_avg_rate_other_language: null,
        field_c_who_hires_services: [],
        field_c_working_days: [],
      };

      this.stepFormServiceOrder = 1;
    },


    calculateRowSuma(models) {
      console.log(models);

      let suma = 0;
      models.forEach((model) => {
        suma += parseInt(this.form[model.model]);
      });
      return suma;
    },

    calculateColumnSuma(rows, index) {
      console.log(rows, index);
      //en esta funcion lo que quiero es realizar la suma en columna de los valores de los campos de la tabla
      //para ello recibo el objeto row que contiene los campos de la tabla

      let suma = 0;
      rows.forEach((row) => {
        if (row.fields) {
          suma += parseInt(this.form[row.fields[index].model]);
        }
      });

      return suma;
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/4`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              /*
              field_a_rnt_category
              field_a_registered_activities
              field_c_women_in_leadership_last_year
              field_c_foreign_languages_known
              field_c_true_company_statements
              field_c_documents_required_for_hiring
              field_c_employer_obligations_met
              field_c_documents_required_for_company_hiring
              field_c_high_demand_months_last_year
              field_c_employee_security_measures
              field_c_employee_wellbeing_measures


              itemsRntCategory
              itemsRegisteredActivities
              itemsWomenInLeadershipLastYear
              itemsForeignLanguagesKnown
              itemsTrueCompanyStatements
              itemsDocumentsRequiredForHiring
              itemsEmployerObligationsMet
              itemsDocumentsRequiredForCompanyHiring
              itemsHighDemandMonthsLastYear
              itemsEmployeeSecurityMeasures
              itemsEmployeeWellbeingMeasures


              */

              const field_a_rnt_category = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_rnt_category"
              );
              if (field_a_rnt_category) {
                this.formItems.itemsRntCategory = field_a_rnt_category.options;
              }

              const field_a_registered_activities = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_registered_activities"
              );
              if (field_a_registered_activities) {
                this.formItems.itemsRegisteredActivities =
                  field_a_registered_activities.options;
              }

              const field_c_women_in_leadership_last_year = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_women_in_leadership_last_year"
              );
              if (field_c_women_in_leadership_last_year) {
                this.formItems.itemsWomenInLeadershipLastYear =
                  field_c_women_in_leadership_last_year.options;
              }

              const field_c_foreign_languages_known = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_foreign_languages_known"
              );
              if (field_c_foreign_languages_known) {
                this.formItems.itemsForeignLanguagesKnown =
                  field_c_foreign_languages_known.options;
              }

              const field_c_true_company_statements = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_true_company_statements"
              );
              if (field_c_true_company_statements) {
                this.formItems.itemsTrueCompanyStatements =
                  field_c_true_company_statements.options;
              }

              const field_c_documents_required_for_hiring = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_documents_required_for_hiring"
              );
              if (field_c_documents_required_for_hiring) {
                this.formItems.itemsDocumentsRequiredForHiring =
                  field_c_documents_required_for_hiring.options;
              }

              const field_c_employer_obligations_met = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_employer_obligations_met"
              );
              if (field_c_employer_obligations_met) {
                this.formItems.itemsEmployerObligationsMet =
                  field_c_employer_obligations_met.options;
              }

              const field_c_documents_required_for_company_hiring = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_documents_required_for_company_hiring"
              );
              if (field_c_documents_required_for_company_hiring) {
                this.formItems.itemsDocumentsRequiredForCompanyHiring =
                  field_c_documents_required_for_company_hiring.options;
              }

              const field_c_high_demand_months_last_year = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_high_demand_months_last_year"
              );
              if (field_c_high_demand_months_last_year) {
                const monthOrder = {
                  Enero: 1,
                  Febrero: 2,
                  Marzo: 3,
                  Abril: 4,
                  Mayo: 5,
                  Junio: 6,
                  Julio: 7,
                  Agosto: 8,
                  Septiembre: 9,
                  Octubre: 10,
                  Noviembre: 11,
                  Diciembre: 12,
                };

                // Sort the months based on the logical order
                field_c_high_demand_months_last_year.options.sort(
                  (a, b) => monthOrder[a.text] - monthOrder[b.text]
                );

                this.formItems.itemsHighDemandMonthsLastYear =
                  field_c_high_demand_months_last_year.options;
              }

              const field_c_employee_security_measures = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_employee_security_measures"
              );
              if (field_c_employee_security_measures) {
                this.formItems.itemsEmployeeSecurityMeasures =
                  field_c_employee_security_measures.options;
              }

              const field_c_employee_wellbeing_measures = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_employee_wellbeing_measures"
              );
              if (field_c_employee_wellbeing_measures) {
                this.formItems.itemsEmployeeWellbeingMeasures =
                  field_c_employee_wellbeing_measures.options;
              }
            }

            if (response.data.autocomplete) {
              // this.activeWatch = false;
              response.data.autocomplete.forEach((item) => {
                // Verifica si el campo existe en el formulario de manera segura
                if (Object.prototype.hasOwnProperty.call(this.form, item.field)) {
                  // Actualiza el campo del formulario con el valor correspondiente
                  this.form[item.field] = item.text_response || item.selected_options;
                }
              });
            }

            //   setTimeout(() => {
            //     this.activeWatch = true;
            //   }, 1000);

            // }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },
  },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
  margin: 0;
}
</style>
